import {
  NOTIFICATION_LIST_START,
  NOTIFICATION_LIST_FAILED,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_READ_FAILED,
  NOTIFICATION_READ_ALL_FAILED,
  GO_TO_NOTIFICATION 
} from "../actions";

import { updateObject } from "../../util/utility";

const initialState = [];
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case NOTIFICATION_LIST_START:
        return updateObject(state, { loading: true, error: null });
      case NOTIFICATION_LIST_SUCCESS:
        return updateObject(state, {
          loading: false,
          error: null,
          Notifications: action.state.data,
        });     
      case NOTIFICATION_LIST_FAILED:
        return updateObject(state, { loading: false, error: action.error });
      case NOTIFICATION_READ_FAILED:
        return updateObject(state, { loading: false, error: action.error });
      case NOTIFICATION_READ_ALL_FAILED:
        return updateObject(state, { loading: false, error: action.error });
      case GO_TO_NOTIFICATION:
        return updateObject(state, { loading: false, gotodata: action.data });
     
      default:
        return updateObject(state, { loading: false, error: null });
    }
  };
  
  export default reducer;
  