import {
  GET_PLANS_DATA
} from "../actions";

import { updateProjectObject } from "../../util/utility";

const INIT_STATE = {
  data:{}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PLANS_DATA:
      return { ...state, loading: false, list: action.data };
    default:
      return { ...state };
  }
};
