import { GET_USER, GET_USER_SUCCESS, GET_USER_ERROR } from "../actions";

const INIT_STATE = {
  loading: false,
  error: "",
  UserInfo: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER:
      return { ...state, loading: false, error: "" };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        type: action.type,
        UserInfo: action.message.data,
      };
    case GET_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
      };
    default:
      return { ...state };
  }
};
