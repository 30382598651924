import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import authUser from "./auth/reducer";
import getUser from "./user/reducer";
import superadminUser from "./superadmin/reducer";
import uploadFile from "./uploadFile/reducer";
import projCategory from "./category/reducer";
import project from "./project/reducer";
import notifications from "./notifications/reducer";
import task from "./task/reducer";
import todoApp from "./todo/reducer";
import chatApp from "./chat/reducer";
import surveyListApp from "./surveyList/reducer";
import surveyDetailApp from "./surveyDetail/reducer";
import dashboard from "./dashboard/reducer";
import plan from "./plans/reducer";

const reducers = combineReducers({
  menu,
  settings,
  plan,
  authUser,
  projCategory,
  getUser,
  superadminUser,
  uploadFile,
  project,
  notifications,
  task,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  dashboard
});

export default reducers;
