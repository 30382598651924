import {
  TASK_ADD_NEW_MAIN_IMG,
  TASK_ADD_NEW_MAIN_IMG_SUCCESS,
  TASK_ADD_NEW_MAIN_IMG_ERROR,
  GET_TASK_MAIN_IMAGES,
  GET_TASK_MAIN_IMAGES_SUCCESS,
  GET_TASK_MAIN_IMAGES_ERROR,
  TASK_ADD_NEW_PROD_MAIN_IMG,
  TASK_ADD_NEW_PROD_MAIN_IMG_SUCCESS,
  TASK_ADD_NEW_PROD_MAIN_IMG_ERROR,
  GET_TASK_IMAGE,
  GET_TASK_IMAGE_SUCCESS,
  GET_TASK_IMAGE_ERROR,
  ADD_VERSION_IMAGE,
  ADD_VERSION_IMAGE_SUCCESS,
  ADD_VERSION_IMAGE_ERROR,
  DELETE_TASK_MAIN_IMAGE,
  DELETE_TASK_MAIN_IMAGE_SUCCESS,
  DELETE_TASK_MAIN_IMAGE_ERROR,
  GET_PREPROD_TASK_IMAGE,
  GET_PREPROD_TASK_IMAGE_SUCCESS,
  GET_PREPROD_TASK_IMAGE_ERROR,
  SHARE_IMG_LINK,
  SHARE_IMG_LINK_SUCCESS,
  SHARE_IMG_LINK_ERROR,
  ADD_TASK_COMMENT,
  ADD_TASK_COMMENT_SUCCESS,
  ADD_TASK_COMMENT_ERROR,
  DELETE_TASK_COMMENT,
  DELETE_TASK_COMMENT_SUCCESS,
  DELETE_TASK_COMMENT_ERROR,
  CHANGE_TASK_STATUS,
  CHANGE_TASK_STATUS_SUCCESS,
  CHANGE_TASK_STATUS_ERROR,
  CATEGORY_TASKS_LIST_WITH_VERSIONIMG_START,
  CATEGORY_TASKS_LIST_WITH_VERSIONIMG_SUCCESS,
  CATEGORY_TASKS_LIST_WITH_VERSIONIMG_ERROR,
  CATEGORY_TASKS_COMPARE_IMAGE,
  CATEGORY_TASKS_COMPARE_IMAGE_SUCCESS,
  CATEGORY_TASKS_COMPARE_IMAGE_ERROR,
  CATEGORY_TASKS_COMPARE_IMAGE_PREPROD,
  CATEGORY_TASKS_COMPARE_IMAGE_PREPROD_SUCCESS,
  CATEGORY_TASKS_COMPARE_IMAGE_PREPROD_ERROR,
  TASK_ADD_NEW_ANNOTATE_MAIN_IMG,
  TASK_ADD_NEW_ANNOTATE_MAIN_IMG_SUCCESS,
  TASK_ADD_NEW_ANNOTATE_MAIN_IMG_ERROR,
  CHANGE_TASK_MAINIMG_STATUS,
  CHANGE_TASK_MAINIMG_SUCCESS,
  CHANGE_TASK_MAINIMG_ERROR,
  TASKS_MAINIMG_LIST_START,
  TASKS_MAINIMG_LIST_START_SUCCESS,
  TASKS_MAINIMG_LIST_START_ERROR,
  GET_TASK_IMAGE_WO,
  GET_TASK_IMAGE_SUCCESS_WO,
  GET_TASK_IMAGE_ERROR_WO,
  GET_TASK_VERSION_IMAGE,
  GET_TASK_VERSION_IMAGE_SUCCESS,
  GET_TASK_VERSION_IMAGE_ERROR,
} from "../actions";

import { updateObject } from "../../util/utility";

const INIT_STATE = {
  loading1: false,
  loaderSave: false,
  loaderMain: false,
  uplImgLoader: false,
  error: "",
  mainImagesList: [],
  shareImageLink:"",
  categoryTasksListData: [],
  TasksMainImgListData: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TASK_ADD_NEW_MAIN_IMG:
      return {
        ...state,
        message: "",
        loading1: true,
        uplImgLoader: true,
      };
    case TASK_ADD_NEW_MAIN_IMG_SUCCESS:
      return {
        ...state,
        loading1: false,
        addNewMainImg: action.message.data.success,
        uplImgLoader: false,
      };
    case TASK_ADD_NEW_MAIN_IMG_ERROR:
      return {
        ...state,
        loading1: false,
        error: action.error,
        uplImgLoader: false,
      };
    case GET_TASK_MAIN_IMAGES:
      return { ...state, loading1: true, error: "" };
    case GET_TASK_MAIN_IMAGES_SUCCESS:
      let imagesList = action.message.data.data;
      return {
        loading1: false,
        type: action.type,
        mainImagesList: imagesList,
      };
    case GET_TASK_MAIN_IMAGES_ERROR:
      return {
        ...state,
        loading1: false,
        error: "",
      };
    case TASK_ADD_NEW_PROD_MAIN_IMG:
      return {
        ...state,
        message: "",
        loading1: true,
        uplImgLoader: true,
      };
    case TASK_ADD_NEW_PROD_MAIN_IMG_SUCCESS:
      return {
        ...state,
        loading1: false,
        uplImgLoader: false,
        addNewProdMainImg: action.message.data.success,
      };
    case TASK_ADD_NEW_PROD_MAIN_IMG_ERROR:
      return {
        ...state,
        loading1: false,
        uplImgLoader: false,
        error: action.error,
      };
    case GET_TASK_IMAGE:
      return { ...state, loaderMain: true, dImgFlag: true, error: "" };
    case GET_TASK_IMAGE_SUCCESS:
      let image = action.message.data.data.img;
      let cmtMainImage = action.message.data.data.mainImage;
      let version = action.message.data.data.version;
      return {
        ...state,
        loaderMain: false,
        dImgFlag: false,
        mainImage: image,
        cmtMainImage: cmtMainImage,
        originalImg: action.message.data.data.main_img,
        taskData: action.message.data.data,
        version:version
      };
    case GET_TASK_IMAGE_ERROR:
      return {
        ...state,
        loaderMain: false,
        dImgFlag: false,
        error: "",
    };
    case ADD_VERSION_IMAGE:
      return { ...state, loaderSave: true, error: "" };
    case ADD_VERSION_IMAGE_SUCCESS:
      let new_image = action.message.data.data.img;
      return {
        ...state,
        taskImageData: new_image,
        loaderSave: false,
      };
    case ADD_VERSION_IMAGE_ERROR:
      return {
        ...state,
        loaderSave: false,
        error: action.error,
        taskImageData: "error",
    };

    case ADD_TASK_COMMENT:
      return { ...state, loadingcmt: true, error: "" };
    case ADD_TASK_COMMENT_SUCCESS:
      let add_cmt = action.message.data.data;
      return {
        ...state,
        add_comment: add_cmt,
        loadingcmt: false,
      };
    case ADD_TASK_COMMENT_ERROR:
      return {
        ...state,
        loadingcmt: false,
        error: action.error,
    };

    case DELETE_TASK_COMMENT:
      return { ...state, loadingcmt: true, error: "" };
    case DELETE_TASK_COMMENT_SUCCESS:
      let del_cmt = action.message.data.data;
      return {
        ...state,
        delete_comment: del_cmt,
        loadingcmt: false,
      };
    case DELETE_TASK_COMMENT_ERROR:
      return {
        ...state,
        loadingcmt: false,
        error: action.error,
    };

    case DELETE_TASK_MAIN_IMAGE:
      return {
        ...state,
        message: "",
        loading1: true,
      };
    case DELETE_TASK_MAIN_IMAGE_SUCCESS:
      //console.log('action',action);
      return {
        ...state,
        loading1: false,
        delTaskMainimg: action.message,
      };
    case DELETE_TASK_MAIN_IMAGE_ERROR:
      return {
        ...state,
        loading1: false,
        error: action.error,
      };

    case GET_PREPROD_TASK_IMAGE:
      return { ...state, loaderMain: true, dImgFlag: true, error: "" };
    case GET_PREPROD_TASK_IMAGE_SUCCESS:
      let img = action.message.data.data.img;
      let cmtMainImagePreProd = action.message.data.data.mainImage; 
      return {
        ...state,
        mainImage: img,
        loaderMain: false,
        dImgFlag: false,
        taskData: action.message.data.data,
        cmtMainImage: cmtMainImagePreProd,
      };
    case GET_PREPROD_TASK_IMAGE_ERROR:
      return {
        ...state,
        loaderMain: false,
        dImgFlag: false,
        error: "",
    };

    case SHARE_IMG_LINK:
      return { ...state, loaderSave: true, error: "" };
    case SHARE_IMG_LINK_SUCCESS:
      let datas = action.message;
      return {
        ...state,
        loaderSave: false,
        shareImageLink: datas,
      };
    case SHARE_IMG_LINK_ERROR:
      return {
        ...state,
        loaderSave: false,
        error: action.error,
    };

    case CHANGE_TASK_STATUS:
      return { ...state, loaderChgStatus: true, error: "" };
    case CHANGE_TASK_STATUS_SUCCESS:
      let statusData = action.message.data.data;
      return {
        ...state,
        loaderChgStatus: false,
        changeStatus: statusData,
      };
    case CHANGE_TASK_STATUS_ERROR:
      return {
          ...state,
          loaderChgStatus: false,
          error: action.error,
      };
    case CATEGORY_TASKS_LIST_WITH_VERSIONIMG_START:
      return updateObject(state, { loading1: true, error: null });
    case CATEGORY_TASKS_LIST_WITH_VERSIONIMG_SUCCESS:
      // console.log("action", action.categoryTasksData.data.data);
      return updateObject(state, {
        loading1: false,
        error: null,
        categoryTasksListData: action.categoryTasksData.data.data,
      });
    case CATEGORY_TASKS_LIST_WITH_VERSIONIMG_ERROR:
      return updateObject(state, {
        loading1: false,
        error: action.error,
      });
    
    case CATEGORY_TASKS_COMPARE_IMAGE:
      return updateObject(state, { loading1: true, error: null });
    case CATEGORY_TASKS_COMPARE_IMAGE_SUCCESS:
      //console.log(action.categoryTasksData)
      return updateObject(state, {
        loading1: false,
        error: null,
        tasksCompareData: action.categoryTasksData.data.data,
      });
    case CATEGORY_TASKS_COMPARE_IMAGE_ERROR:
      return updateObject(state, {
        loading1: false,
        error: action.error,
      });
    
      case CATEGORY_TASKS_COMPARE_IMAGE_PREPROD:
        return updateObject(state, { loading1: true, error: null });
      case CATEGORY_TASKS_COMPARE_IMAGE_PREPROD_SUCCESS:
        return updateObject(state, {
          loading1: false,
          error: null,
          tasksCompareData: action.categoryTasksData.data.data,
        });
      case CATEGORY_TASKS_COMPARE_IMAGE_PREPROD_ERROR:
        return updateObject(state, {
          loading1: false,
          error: action.error,
        });
    
      case TASK_ADD_NEW_ANNOTATE_MAIN_IMG:
        return {
          ...state,
          message: "",
          loading1: true,
          loaderChgVersion: "",
          dImgFlag: "",
        };
      case TASK_ADD_NEW_ANNOTATE_MAIN_IMG_SUCCESS:
        return {
          ...state,
          loading1: false,
          addNewAnnotateMainImg: action.message.data.success,
          annotateImage: action.message.data.data.img,
          loaderChgVersion: "",
          dImgFlag: "",
        };
      case TASK_ADD_NEW_ANNOTATE_MAIN_IMG_ERROR:
        return {
          ...state,
          loading1: false,
          error: action.error,
          loaderChgVersion: "",
          dImgFlag: "",
        }; 

      case CHANGE_TASK_MAINIMG_STATUS:
        return { ...state, loaderSave: true, error: "" };
      case CHANGE_TASK_MAINIMG_SUCCESS:
        let mainImgStatusData = action.message.data.data;
        return {
          ...state,
          loaderSave: false,
          changeStatus: mainImgStatusData,
        };
      case CHANGE_TASK_MAINIMG_ERROR:
        return {
            ...state,
            loaderSave: false,
            error: action.error,
        };

      case TASKS_MAINIMG_LIST_START:
        return updateObject(state, { loading1: true, error: null });
      case TASKS_MAINIMG_LIST_START_SUCCESS:
        return updateObject(state, {
          loading1: false,
          error: null,
          TasksMainImgListData: action.taskMainImagesData.data.data,
        });
      case TASKS_MAINIMG_LIST_START_ERROR:
        return updateObject(state, {
          loading1: false,
          error: action.error,
        });

      case GET_TASK_IMAGE_WO:
        return { ...state,dImgFlag: "", error: "" };
      case GET_TASK_IMAGE_SUCCESS_WO:
        let image_wo = action.message.data.data.img;
        let cmtMainImage_wo = action.message.data.data.mainImage;
        let version_wo = action.message.data.data.version;
        return {
          ...state,
          mainImage: image_wo,
          dImgFlag: "",
          cmtMainImage: cmtMainImage_wo,
          originalImg: action.message.data.data.main_img,
          taskData: action.message.data.data,
          version:version_wo
        };
      case GET_TASK_IMAGE_ERROR_WO:
        return {
          ...state,
          dImgFlag: "",
          error: "",
      };

    case GET_TASK_VERSION_IMAGE:
      return { ...state, loaderChgVersion: true,dImgFlag: true, error: "" };
    case GET_TASK_VERSION_IMAGE_SUCCESS:
      let imageV = action.message.data.data.img;
      let cmtMainImageV = action.message.data.data.mainImage;
      let versionV = action.message.data.data.version;
      return {
        ...state,
        loaderChgVersion: false,
        dImgFlag: false,
        mainImage: imageV,
        cmtMainImage: cmtMainImageV,
        originalImg: action.message.data.data.main_img,
        taskData: action.message.data.data,
        version:versionV
      };
    case GET_TASK_VERSION_IMAGE_ERROR:
      return {
        ...state,
        loaderChgVersion: false,
        dImgFlag: false,
        error: "",
    };
            
    default: {
      return { ...state };
    }
  }
};
