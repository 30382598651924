import {
  GET_DASHBOARD_DATA,
  GET_SUPER_ADMIN_DASHBOARD_DATA
} from "../actions";
import config from "../../config/config";
import { NotificationManager } from "../../../src/components/common/react-notifications";
import axios from "axios";

import * as actions from "../../redux/actions";

export const getDashboardData = (history) => {
  return (dispatch) => {
    let apiEndpoint = "dashboard-data";
    const token = localStorage.getItem("UserToken");
    axios
      .get(config.baseUrl + apiEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch({
          type: GET_DASHBOARD_DATA,
          data: response.data,
        });
      })
      .catch((error) => {
        
      });
  };
};

export const getSuperAdminDashboardData = (defaultYear) => {
  return (dispatch) => {
    let apiEndpoint = "super-admin-dashboard-data";
    const token = localStorage.getItem("UserToken");
    axios
      .post(config.baseUrl + apiEndpoint, defaultYear, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch({
          type: GET_SUPER_ADMIN_DASHBOARD_DATA,
          data: response.data,
        });
      })
      .catch((error) => {
        
      });
  };
};