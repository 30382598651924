import { SET_UPLOAD_FILE, SET_UPLOAD_PROGRESS, SUCCESS_UPLOAD_FILE, FAILURE_UPLOAD_FILE, RETRY_UPLOAD_FILE, TASK_ADD_NEW_PROD_MAIN_IMG, TASK_ADD_NEW_PROD_MAIN_IMG_SUCCESS, TASK_ADD_NEW_PROD_MAIN_IMG_ERROR, FAIL_UPLOAD_FILE, CLOSE_UPLOAD_FILE } from "../actions";
import config from "../../config/config";
import { NotificationManager } from "../../../src/components/common/react-notifications";
import axios from "axios";
import * as actions from "../../redux/actions";

export const setUploadFile = data => ({
  type: SET_UPLOAD_FILE,
  payload: data,
})

export const closeUploadFile = data => ({
  type: CLOSE_UPLOAD_FILE,
  payload: data,
});

export const setUploadProgress = (id, progress) => ({
  type: SET_UPLOAD_PROGRESS,
  payload: {
    id,
    progress,
  },
})

export const successUploadFile = (id, msg) => ({
  type: SUCCESS_UPLOAD_FILE,
  payload: {
    id,
    msg,
  },
})

export const failureUploadFile = id => ({
  type: FAILURE_UPLOAD_FILE,
  payload: id,
})


export const failUploadFile = (id, msg) => ({
  type: FAIL_UPLOAD_FILE,
  payload: {
    id,
    msg,
  },
})

export const uploadFileProdMainImages = (files, payload) => {
  return (dispatch) => {
    if (files.length) {
      files.forEach(async file => {
        const formPayload = new FormData();
        formPayload.append("category_id", payload.category_id);
        formPayload.append("project_id", payload.project_id);
        formPayload.append("upload_prod_images[]",file.file);

        try {
          let apiEndpoint = "upload-task-multiple-prod-main-images-new";
          const token = localStorage.getItem("UserToken");

          await axios
          .post(config.baseUrl + apiEndpoint, formPayload, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            cancelToken: file.cancelSource.token,
            onUploadProgress: progress => {
              const { loaded, total } = progress;
              const percentageProgress = Math.floor((loaded / total) * 100)
              dispatch(setUploadProgress(file.id, percentageProgress));
            },
          })
          .then(response => {
            dispatch({ type: TASK_ADD_NEW_PROD_MAIN_IMG });
            dispatch({
              type: TASK_ADD_NEW_PROD_MAIN_IMG_SUCCESS,
              message: response,
            });
            
            let msg = "";
            if(response.data.data.single_img_message){
              msg = response.data.data.single_img_message;
            }else{
              msg = response.data.data.message;
            }

            dispatch(successUploadFile(file.id,msg));
            if(response.data.data.upload_singlefile_success == 0){
              dispatch(failUploadFile(file.id,msg)) 
            }
          })
          .catch(error => {
            //console.log('error',error)
            if (axios.isCancel(error)) {
              // Do something when user cancel upload
              console.log('cancelled by user')
            }
            
            let msg = "";
            if(error.response.data.single_img_message){
              msg = error.response.data.single_img_message;
            }else{
              msg = error.response.data.message;
            }
            dispatch(failUploadFile(file.id, msg));

            if (!error.response.data.success) {
              dispatch({
                type: TASK_ADD_NEW_PROD_MAIN_IMG_ERROR,
                error: msg,
              });
            }
          })
        } catch (error) {
          //console.log('catch error')
          if (axios.isCancel(error)) {
            // Do something when user cancel upload
            console.log('cancelled by user')
          }
          dispatch(failureUploadFile(file.id));
        }
      })
    }
  }
};

export const retryUpload = (id) => (dispatch, getState) => {
  dispatch({
    type: RETRY_UPLOAD_FILE,
    payload: id,
  })
  const { fileProgress } = getState().uploadFile;
  const reuploadFile = [fileProgress[id]];

  let request2 = {
    project_id: localStorage.getItem("sel_project_id"),
    category_id: localStorage.getItem("sel_category_id"),
  }
  dispatch(actions.uploadFileProdMainImages(reuploadFile,request2));
}
