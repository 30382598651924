module.exports = {
  // baseUrl: "http://172.105.47.232/Backend/api/v1/",
  // siteUrl: "http://172.105.47.232/",
  //AWS  
  baseUrl: "https://app.snello-workflow.com/collaboration-backend-new/collaboration-backend/api/v1/",
  siteUrl: "https://app.snello-workflow.com/",
  //baseUrl: "http://54.219.28.253/BE/collaboration-backend/api/v1/",
  //siteUrl: "http://collaboration.kanhasoftdev.com/",
  // baseUrl: "http://localhost/collaboration-backend-new/api/v1/",
  // siteUrl: "http://localhost:3000/",
};