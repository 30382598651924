import { 
  GET_SUBSCRIBER_LIST_START,
  GET_SUBSCRIBER_LIST_SUCCESS,
  GET_SUBSCRIBER_LIST_FAILED,
  BLOCK_SUBSCRIBER_START,
  BLOCK_SUBSCRIBER_SUCCESS,
  BLOCK_SUBSCRIBER_FAILED,
} from "../actions";
import config from "../../config/config";
import { NotificationManager } from "../../../src/components/common/react-notifications";
import * as actions from "../../redux/actions";
import axios from "axios";

export const getSubscriberList = (payload,history) => {
  return (dispatch) => {
    dispatch({ type: GET_SUBSCRIBER_LIST_START });
    const token = localStorage.getItem("UserToken");
    
    axios
      .post(config.baseUrl + "subscriber-list",payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch({
          type: GET_SUBSCRIBER_LIST_SUCCESS,
          message: response,
        });
        //console.log("Action", response);
      })
      .catch((error) => {
        if (!error.response.data.success) {
          dispatch({
            type: GET_SUBSCRIBER_LIST_FAILED,
            error: error.response.data.message,
          });
          NotificationManager.warning(
            error.response.data.message,
            "Warning",
            3000,
            null,
            null,
            ""
          );
        }
      });
  };
};

export const blockUnblockSubscriber = (payload,history,payload2) => {
  return (dispatch) => {
    dispatch({ type: BLOCK_SUBSCRIBER_START });
    const token = localStorage.getItem("UserToken");
    
    axios
      .post(config.baseUrl + "block-unblock-subscriber",payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch({
          type: BLOCK_SUBSCRIBER_SUCCESS,
          data: response,
        });
        dispatch(actions.getSubscriberList(payload2,history));
      })
      .catch((error) => {
        if (!error.response.data.success) {
          dispatch({
            type: BLOCK_SUBSCRIBER_FAILED,
            error: error.response.data.message,
          });
          NotificationManager.warning(
            error.response.data.message,
            "Warning",
            3000,
            null,
            null,
            ""
          );
        }
      });
  };
};

