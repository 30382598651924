import {
  NOTIFICATION_LIST_START,
  NOTIFICATION_LIST_FAILED,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_READ_START,
  NOTIFICATION_READ_SUCCESS,
  NOTIFICATION_READ_FAILED,
  NOTIFICATION_READ_ALL_FAILED,
  GO_TO_NOTIFICATION 
} from "../actions";
import config from "../../config/config";
import { NotificationManager } from "../../../src/components/common/react-notifications";
import { updateObject } from "../../util/utility";
import axios from "axios";

import * as actions from "../../redux/actions";

export const getNotificationList = (request) => {
  return (dispatch) => {
    dispatch(ongetNotificationListStart());
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
      "UserToken"
    );
    let url = config.baseUrl + "notification-list";
    const token = localStorage.getItem("UserToken");

    axios
    .post(url,request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        dispatch(ongetNotificationListSucceed(response.data));
      })
      .catch(function (error) {
        dispatch(ongetNotificationListFailed(error));
      });
  };
};

const ongetNotificationListStart = () => {
  return {
    type: NOTIFICATION_LIST_START,
  };
};

const ongetNotificationListFailed = (error) => {
  return {
    type: NOTIFICATION_LIST_FAILED,
    error: error,
  };
};

const ongetNotificationListSucceed = (data) => {
  return {
    type: NOTIFICATION_LIST_SUCCESS,
    state: data,
  };
};

export const setReadmsg = (request, callback) => {
  return (dispatch) => {     
    dispatch(onsetReadmsgStart());
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
      "UserToken"
    );
    let url = config.baseUrl + "notification-read";
    const token = localStorage.getItem("UserToken");

    axios
    .post(url,request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) { 
        dispatch(onsetReadmsgSucceed(response.data));
        callback();
        dispatch(actions.getNotificationList(request));
        // NotificationManager.success(
        //   response.data.message,
        //   "Success",
        //   3000,
        //   null,
        //   null,
        //   ""
        // );
      })
      .catch(function (error) {
        dispatch(onsetReadmsgFailed(error));
      });
  };
};

const onsetReadmsgStart = () => {
  return {
    type: NOTIFICATION_READ_START,
    loadingSave: true,
  };
};

const onsetReadmsgSucceed = (data) => {
  return {
    type: NOTIFICATION_READ_SUCCESS,
    state: data,
    loadingSave: false,
  };
};

const onsetReadmsgFailed = (error) => {
  return {
    type: NOTIFICATION_READ_FAILED,
    error: error,
    loadingSave: false,
  };
};
export const setReadNotification = (request) => {
  return (dispatch) => {     
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
      "UserToken"
    );
    let url = config.baseUrl + "usernotification-read";
    const token = localStorage.getItem("UserToken");

    axios
    .post(url,request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
         dispatch(getNotificationList(request));
      })
      .catch(function (error) {
        dispatch(onsetReadmsgFailed(error));
      });
  };
};
const onsetReadNotificationFailed = (error) => {
  return {
    type: NOTIFICATION_READ_FAILED,
    error: error,
  };
};

export const setReadall = (callback) => {
  return (dispatch) => {     
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
      "UserToken"
    );
    let url = config.baseUrl + "usernotification-read-all";
    const token = localStorage.getItem("UserToken");

    axios
    .post(url,{}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        callback();
         dispatch(getNotificationList());
      })
      .catch(function (error) {
        dispatch(onsetReadallFailed(error));
      });
  };
};
const onsetReadallFailed = (error) => {
  return {
    type: NOTIFICATION_READ_ALL_FAILED,
    error: error,
  };
};
export const gotoNotification = (request) => {
  return {
    type: GO_TO_NOTIFICATION,
    data: request,
  };
};



export const notifyTaskUsers = (request) => {
  return (dispatch) => {     
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
      "UserToken"
    );
    let url = config.baseUrl + "notify-task-user";
    const token = localStorage.getItem("UserToken");

    axios
    .post(url,request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        NotificationManager.success(
          "You requested a review.",
          "Success",
          3000,
          null,
          null,
          ""
        );
      })
      .catch(function (error) {
        
      });
  };
};



