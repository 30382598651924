import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHECK_TOKEN,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_ERROR,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPLOAD_AVTAR,
  UPLOAD_AVTAR_SUCCESS,
  UPLOAD_AVTAR_ERROR,
  GET_SINGLE_MEMBER_DETAIL,
  GET_SINGLE_MEMBER_DETAIL_SUCCESS,
  GET_SINGLE_MEMBER_DETAIL_ERROR,
  REGISTER_TEAM_MEMBER,
  REGISTER_TEAM_MEMBER_SUCCESS,
  REGISTER_TEAM_MEMBER_ERROR,
  UPLOAD_PROFILE_PHOTO,
  UPLOAD_PROFILE_PHOTO_SUCCESS,
  UPLOAD_PROFILE_PHOTO_ERROR,
  NOTIFICATION_ONOFF_START,
  NOTIFICATION_ONOFF_SUCCESS,
  NOTIFICATION_ONOFF_FAILED,
  loginUser as userlOGIN
} from "../actions";
import config from "../../config/config";
import { NotificationManager } from "../../../src/components/common/react-notifications";
import axios from "axios";
import firebase from 'firebase';
import * as actions from "../../redux/actions";
import { userService } from "../../services";
export const loginUser = (data, history) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_USER });
    axios
      .post(config.baseUrl + "login", data)
      .then(function (response) {
        firebase.auth().signInWithEmailAndPassword(data.email, "firebase@123")
          .then((users) => {
            //console.log('firebase login')
            dispatch({
              type: LOGIN_USER_SUCCESS,
              message: response.data.message,
              payload: response.data.data.token,
            });
            //console.log(response);
            NotificationManager.success(
              "You are logged-in",
              "",
              3000,
              null,
              null,
              ""
            );
            localStorage.setItem("UserToken", response.data.data.token);
            localStorage.setItem("IsSuperadmin", response.data.data.user_info.is_superadmin);
            history.push("/app/dashboards/default");
            //setTimeout(function () { window.location = '/' }, 1000);
          }).catch(function (error) {
            console.log("firebase error: ", error);
            dispatch({
              type: LOGIN_USER_ERROR,
              error: "Something went wrong",
            });
          });
      })
      .catch(function (error) {
       // console.log(error.response.data.success);
        if (!error.response.data.success) {
          dispatch({
            type: LOGIN_USER_ERROR,
            error: error.response.data.message,
          });
        }
      });
  };
};

export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const forgotPassword = (forgotUserMail, history) => {
  return async (dispatch) => {
    dispatch({ type: FORGOT_PASSWORD });
    axios
      .post(config.baseUrl + "password/create", forgotUserMail)
      .then(function (response) {
        dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
          message: response.data.message,
          payload: response,
        });

        NotificationManager.success(
          response.data.message,
          "Success",
          3000,
          null,
          null,
          ""
        );
        history.push("/user/login");
      })
      .catch(function (error) {
        if (!error.response.data.success) {
          dispatch({
            type: FORGOT_PASSWORD_ERROR,
            error: error.response.data.message,
          });
          NotificationManager.warning(
            error.response.data.message,
            "Warning",
            3000,
            null,
            null,
            ""
          );
        }
      });
  };
};
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

export const resetPassword = (token, history) => {
  return async (dispatch) => {
    dispatch({ type: RESET_PASSWORD });

    axios
      .post(config.baseUrl + "password/reset", token)
      .then(function (response) {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: response,
        });
        NotificationManager.success(
          response.data.message,
          "",
          3000,
          null,
          null,
          ""
        );
        history.push("/user/login");
      })
      .catch(function (error) {
        if (!error.response.data.success) {
          dispatch({
            type: FORGOT_PASSWORD_ERROR,
            error: error.response.data.message,
          });
          NotificationManager.warning(
            error.response.data.message,
            "Warning",
            3000,
            null,
            null,
            ""
          );
        }
      });
  };
};
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword,
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

export const registerUser = (data, history, state) => {
  return async (dispatch) => {
    dispatch({ type: REGISTER_USER });

    axios
      .post(config.baseUrl + "register", data)
      .then(function (response) {
        firebase.auth().createUserWithEmailAndPassword(data.email, "firebase@123").then((users) => {
          //console.log('Register success')
          
          //add firebaseid
          let reqPar = {
            user_id: response.data.data.user_info.id,
            firebase_id: users.user.uid,
          }
          dispatch(actions.updateUserFirebaseId(reqPar,history));

          dispatch({
            type: REGISTER_USER_SUCCESS,
            message: response.data.message,
            payload: response,
          });
          NotificationManager.success(
            response.data.message,
            "",
            3000,
            null,
            null,
            ""
          );
          dispatch(userlOGIN(data, history));
          // history.push("/user/login");

          state.setState({
            loading: false
          })
        }).catch(function (error) {
          state.setState({
            loading: false
          })
          console.log("firebase error: ", error);
          dispatch({
            type: REGISTER_USER_ERROR,
            error: "Something went wrong",
          });
        });
      })
      .catch(function (error) {
        //console.log("actionin reg", error.response.data.message);
        if (!error.response.data.success) {
          dispatch({
            type: REGISTER_USER_ERROR,
            message: error.response.data.message,
          });
          NotificationManager.warning(
            error.response.data.message,
            "Warning",
            3000,
            null,
            null,
            ""
          );
        }
      });
  };
};

export const logoutUser = (history) => {
  return async (dispatch) => {
    firebase.auth().signOut();
    dispatch({ type: LOGOUT_USER });
    localStorage.removeItem("UserToken");
    window.location.href="/user/login";
    
  };
};

export const checkToken = (payload, history) => {
  return async (dispatch) => {
    dispatch({ type: CHECK_TOKEN });

    axios
      .get(config.baseUrl + "password/find/" + payload)
      .then(function (response) {
        dispatch({
          type: CHECK_TOKEN_SUCCESS,
          message: response,
        });
      })
      .catch(function (error) {
        if (!error.response.data.success) {
          dispatch({
            type: CHECK_TOKEN_ERROR,
            error: error.response.data.message,
          });
          // NotificationManager.warning(
          //   error.response.data.message,
          //   "Warning",
          //   3000,
          //   null,
          //   null,
          //   ""
          // );
        }
      });
  };
};
export const getUser = (history) => {
  return (dispatch) => {
    dispatch({ type: GET_USER });
    let apiEndpoint = "user-profile";
    const token = localStorage.getItem("UserToken");
    axios
      .get(config.baseUrl + "user-profile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch({
          type: GET_USER_SUCCESS,
          message: response,
        });
        //console.log("Action", response);
      })
      .catch((error) => {
        if (!error.response.data.success) {
          dispatch({
            type: GET_USER_ERROR,
            error: error.response.data.message,
          });
          NotificationManager.warning(
            error.response.data.message,
            "Warning",
            3000,
            null,
            null,
            ""
          );
        }
      });
  };
};

export const updateUserFirebaseId = (payload, history) => {
  return async (dispatch) => {
    axios
      .post(config.baseUrl + "update-firebaseid", payload, {
        headers: {},
      })
      .then((response) => {
        //console.log('update success');
      })
      .catch(function (error) {
        console.log('update error');
      });
  };
};

export const updateUser = (payload, history) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_USER });
    const token = localStorage.getItem("UserToken");
    axios
      .post(config.baseUrl + "update-profile", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          //"Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response) {
          dispatch({ type: UPDATE_USER_SUCCESS, userData: response });

          NotificationManager.success(
            response.data.message,
            "Success",
            3000,
            null,
            null,
            ""
          );
          history.push("/app/dashboards/default");
        }
      })
      .catch(function (error) {
        dispatch({
          type: UPDATE_USER_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const uploadImage = (payload, history) => {
  return async (dispatch) => {
    dispatch({ type: UPLOAD_AVTAR });
    //console.log("Upload image in action", payload);
    const token = localStorage.getItem("UserToken");
  };
};

export const getSingleMemberDetail = (payload, history) => {
  return async (dispatch) => {
    dispatch({ type: GET_SINGLE_MEMBER_DETAIL });

    axios
      .get(config.baseUrl + "team-members-detail/" + payload)
      .then(function (response) {
        dispatch({
          type: GET_SINGLE_MEMBER_DETAIL_SUCCESS,
          message: response,
        });
      })
      .catch(function (error) {
        if (!error.response.data.success) {
          dispatch({
            type: GET_SINGLE_MEMBER_DETAIL_ERROR,
            error: error.response.data.message,
          });
          // NotificationManager.warning(
          //   error.response.data.message,
          //   "Warning",
          //   3000,
          //   null,
          //   null,
          //   ""
          // );
        }
      });
  };
};

export const registerTeamMember = (data, history, state) => {
  return async (dispatch) => {
    dispatch({ type: REGISTER_TEAM_MEMBER });

    axios
      .post(config.baseUrl + "member_register", data)
      .then(function (response) {
        firebase.auth().createUserWithEmailAndPassword(data.email, "firebase@123").then((users) => {
          state.setState({
            loading: false
          })
          //console.log('Register success')
          //add firebaseid
          let reqPar = {
            user_id: response.data.data.user_info.id,
            firebase_id: users.user.uid,
          }
          dispatch(actions.updateUserFirebaseId(reqPar,history));

          dispatch({
            type: REGISTER_TEAM_MEMBER_SUCCESS,
            message: response.data.message,
            payload: response,
          });
          NotificationManager.success(
            response.data.message,
            "Success",
            3000,
            null,
            null,
            ""
          );          
          dispatch(userlOGIN(data, history));
          // history.push("/user/login");
        }).catch(function (error) {
          state.setState({
            loading: false
          })
          console.log("firebase error: ", error);
          dispatch({
            type: REGISTER_TEAM_MEMBER_ERROR,
            error: "Something went wrong",
          });
        });
      })
      .catch(function (error) {
        state.setState({
          loading: false
        })
        //console.log("actionin reg", error);
        if (!error.response.data.success) {
          dispatch({
            type: REGISTER_TEAM_MEMBER_ERROR,
            message: error.response.data.message,
          });
          NotificationManager.warning(
            "Something went wrong!!",
            "Warning",
            3000,
            null,
            null,
            ""
          );
        }
      });
  };
};

export const uploadProfilePhoto = (payload, history) => {
  return async (dispatch) => {
    dispatch({ type: UPLOAD_PROFILE_PHOTO });
    const token = localStorage.getItem("UserToken");
    axios
      .post(config.baseUrl + "update-profile-photo", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response) {
          dispatch({ type: UPLOAD_PROFILE_PHOTO_SUCCESS, userData: response });
          dispatch(actions.getUser(history));

          NotificationManager.success(
            response.data.message,
            "Success",
            3000,
            null,
            null,
            ""
          );
        }
      })
      .catch(function (error) {
        dispatch({
          type: UPLOAD_PROFILE_PHOTO_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const settingNotiOnOff = (payload, history) => {
  return async (dispatch) => {
    dispatch({ type: NOTIFICATION_ONOFF_START });
    const token = localStorage.getItem("UserToken");
    axios
      .post(config.baseUrl + "setting-notification", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response) {
          dispatch({ type: NOTIFICATION_ONOFF_SUCCESS, userNotiData: response });
          //dispatch(actions.getUser(history));
        }
      })
      .catch(function (error) {
        dispatch({
          type: NOTIFICATION_ONOFF_FAILED,
          error: error.response.data.message,
        });
      });
  };
};