import {
  TASK_ADD_NEW_MAIN_IMG,
  TASK_ADD_NEW_MAIN_IMG_SUCCESS,
  TASK_ADD_NEW_MAIN_IMG_ERROR,
  GET_TASK_MAIN_IMAGES,
  GET_TASK_MAIN_IMAGES_SUCCESS,
  GET_TASK_MAIN_IMAGES_ERROR,
  GET_TASK_IMAGE,
  GET_TASK_IMAGE_SUCCESS,
  GET_TASK_IMAGE_ERROR,
  ADD_VERSION_IMAGE,
  ADD_VERSION_IMAGE_SUCCESS,
  ADD_VERSION_IMAGE_ERROR,
  DELETE_TASK_MAIN_IMAGE,
  DELETE_TASK_MAIN_IMAGE_SUCCESS,
  DELETE_TASK_MAIN_IMAGE_ERROR,
  GET_PREPROD_TASK_IMAGE,
  GET_PREPROD_TASK_IMAGE_SUCCESS,
  GET_PREPROD_TASK_IMAGE_ERROR,
  SHARE_IMG_LINK,
  SHARE_IMG_LINK_SUCCESS,
  SHARE_IMG_LINK_ERROR,
  ADD_TASK_COMMENT,
  ADD_TASK_COMMENT_SUCCESS,
  ADD_TASK_COMMENT_ERROR,
  DELETE_TASK_COMMENT,
  DELETE_TASK_COMMENT_SUCCESS,
  DELETE_TASK_COMMENT_ERROR,
  CHANGE_TASK_STATUS,
  CHANGE_TASK_STATUS_SUCCESS,
  CHANGE_TASK_STATUS_ERROR,
  CATEGORY_TASKS_LIST_WITH_VERSIONIMG_START,
  CATEGORY_TASKS_LIST_WITH_VERSIONIMG_SUCCESS,
  CATEGORY_TASKS_LIST_WITH_VERSIONIMG_ERROR,
  CATEGORY_TASKS_COMPARE_IMAGE,
  CATEGORY_TASKS_COMPARE_IMAGE_SUCCESS,
  CATEGORY_TASKS_COMPARE_IMAGE_ERROR,
  CATEGORY_TASKS_COMPARE_IMAGE_PREPROD,
  CATEGORY_TASKS_COMPARE_IMAGE_PREPROD_SUCCESS,
  CATEGORY_TASKS_COMPARE_IMAGE_PREPROD_ERROR,
  TASK_ADD_NEW_ANNOTATE_MAIN_IMG,
  TASK_ADD_NEW_ANNOTATE_MAIN_IMG_SUCCESS,
  TASK_ADD_NEW_ANNOTATE_MAIN_IMG_ERROR,
  CHANGE_TASK_MAINIMG_STATUS,
  CHANGE_TASK_MAINIMG_SUCCESS,
  CHANGE_TASK_MAINIMG_ERROR,
  TASKS_MAINIMG_LIST_START,
  TASKS_MAINIMG_LIST_START_SUCCESS,
  TASKS_MAINIMG_LIST_START_ERROR,
  GET_TASK_IMAGE_WO,
  GET_TASK_IMAGE_SUCCESS_WO,
  GET_TASK_IMAGE_ERROR_WO,
  GET_TASK_VERSION_IMAGE,
  GET_TASK_VERSION_IMAGE_SUCCESS,
  GET_TASK_VERSION_IMAGE_ERROR,
} from "../actions";

import config from "../../config/config";
import { NotificationManager } from "../../../src/components/common/react-notifications";
import axios from "axios";

import * as actions from "../../redux/actions";

export const addNewMainImages = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: TASK_ADD_NEW_MAIN_IMG });
    let apiEndpoint = "upload-task-multiple-main-images";
    const token = localStorage.getItem("UserToken");
    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          let requestParams = {
            task_id: response.data.data.task_id,
            tableState: {
              page: 1,
              rowsPerPage: 6,
              sortBy: "id",
              sortDirection: "DESC",
            },
          };
          dispatch(actions.getTaskMainImagesList(requestParams, history));

          dispatch({
            type: TASK_ADD_NEW_MAIN_IMG_SUCCESS,
            message: response,
          });
          NotificationManager.success(
            response.data.message,
            "Success",
            3000,
            null,
            null,
            ""
          );
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        if (!error.response.data.success) {
          dispatch({
            type: TASK_ADD_NEW_MAIN_IMG_ERROR,
            error: error.response.data.message,
          });
          NotificationManager.warning(
            error.response.data.message,
            "Warning",
            3000,
            null,
            null,
            ""
          );
        }
      });
  };
};

export const getTaskMainImagesList = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: GET_TASK_MAIN_IMAGES });
    let apiEndpoint = "task_main_images";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response) {
          dispatch({ type: GET_TASK_MAIN_IMAGES_SUCCESS, message: response });
        }
      })
      .catch(function (error) {
        dispatch({
          type: GET_TASK_MAIN_IMAGES_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const getTaskVersionImg = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: GET_TASK_VERSION_IMAGE });
    let apiEndpoint = "task_image";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response) {
          dispatch({ type: GET_TASK_VERSION_IMAGE_SUCCESS, message: response });
        }
      })
      .catch(function (error) {
        dispatch({
          type: GET_TASK_VERSION_IMAGE_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const getImageFromTaskId = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: GET_TASK_IMAGE });
    let apiEndpoint = "task_image";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response) {
          dispatch({ type: GET_TASK_IMAGE_SUCCESS, message: response });
        }
      })
      .catch(function (error) {
        dispatch({
          type: GET_TASK_IMAGE_ERROR,
          error:  error.response && error.response.data && error.response.data.message,
        });
        NotificationManager.warning(
          error.response && error.response.data && error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const getImageFromTaskIdWithOutLoader = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: GET_TASK_IMAGE_WO });
    let apiEndpoint = "task_image";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response) {
          dispatch({ type: GET_TASK_IMAGE_SUCCESS_WO, message: response });
        }
      })
      .catch(function (error) {
        dispatch({
          type: GET_TASK_IMAGE_ERROR_WO,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const addNewVersionImage = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: ADD_VERSION_IMAGE });
    let apiEndpoint = "add_version_image";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response) {
          let requestParams = {
            task_id: response.data.data.task_id
          };
          let requestPar = {
            category_id: response.data.data.category_id
          };
          dispatch(actions.getImageFromTaskIdWithOutLoader(requestParams, history));
          dispatch(actions.categoryTasksListWithLatestVersion(requestPar, history));
          dispatch({ type: ADD_VERSION_IMAGE_SUCCESS, message: response });
          window.location.reload();
        }
      })
      .catch(function (error) {
        dispatch({
          type: ADD_VERSION_IMAGE_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};


export const addNewVersionImageOutside = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: ADD_VERSION_IMAGE });
    let apiEndpoint = "add_version_image";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          let params = {
            category_id: response.data.data.category_id,
            tableState: {
              page: 1,
              rowsPerPage: 6,
              searchText: "",
              sortBy: "id",
              sortDirection: "DESC",
            },
          };
          dispatch(actions.categoryTasksList(params, history));
          dispatch({ type: ADD_VERSION_IMAGE_SUCCESS, message: response });
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: ADD_VERSION_IMAGE_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const deleteTaskMainImage = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: DELETE_TASK_MAIN_IMAGE });
    let apiEndpoint = "delete-task-main-image";
    const token = localStorage.getItem("UserToken");
    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          dispatch({ type: DELETE_TASK_MAIN_IMAGE_SUCCESS, data: response });

          let params = {
            task_id: response.data.data.task_id,
            tableState: {
              page: 1,
              rowsPerPage: 6,
              sortBy: "id",
              sortDirection: "DESC",
            },
          };
          dispatch(actions.getTaskMainImagesList(params, history));
          
          NotificationManager.success(
            response.data.message,
            "Success",
            3000,
            null,
            null,
            ""
          );
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: DELETE_TASK_MAIN_IMAGE_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const getPreProdTaskMainImage = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: GET_PREPROD_TASK_IMAGE });
    let apiEndpoint = "preprod-task-main-image";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          dispatch({ type: GET_PREPROD_TASK_IMAGE_SUCCESS, message: response });
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: GET_PREPROD_TASK_IMAGE_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const getPreProdTaskMainImageWithOutLoader = (payload, history) => {
  return (dispatch) => {
    let apiEndpoint = "preprod-task-main-image";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          dispatch({ type: GET_PREPROD_TASK_IMAGE_SUCCESS, message: response });
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: GET_PREPROD_TASK_IMAGE_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const sendMainImageLink = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: SHARE_IMG_LINK });
    let apiEndpoint = "share-image-link";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          dispatch({ type: SHARE_IMG_LINK_SUCCESS, message: response });
          
          NotificationManager.success(
            response.data.message,
            "Success",
            3000,
            null,
            null,
            ""
          );
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: SHARE_IMG_LINK_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const sendMainImageLinkAll = (payload, history) => {
  return (dispatch) => {
    // dispatch({ type: SHARE_IMG_LINK });
    let apiEndpoint = "share-image-link-all";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          // dispatch({ type: SHARE_IMG_LINK_SUCCESS, message: response });
          if(payload.index == 0){
            NotificationManager.success(
              response.data.message,
              "Success",
              3000,
              null,
              null,
              ""
            );

          }
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: SHARE_IMG_LINK_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const addTaskComment = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: ADD_TASK_COMMENT });
    let apiEndpoint = "add-task-comment";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          let requestParams = {
            task_id: response.data.data.task_id
          };
          dispatch(actions.editTaskDetailLoad(requestParams, history));
          dispatch({ type: ADD_TASK_COMMENT_SUCCESS, message: response });
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: ADD_TASK_COMMENT_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const deleteTaskComment = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: DELETE_TASK_COMMENT });
    let apiEndpoint = "delete-task-comment";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          let requestParams = {
            task_id: response.data.data.task_id
          };
          dispatch(actions.editTaskDetailLoad(requestParams, history));
          dispatch({ type: DELETE_TASK_COMMENT_SUCCESS, message: response });

          NotificationManager.success(
            response.data.message,
            "Success",
            3000,
            null,
            null,
            ""
          );
          
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: DELETE_TASK_COMMENT_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const changeTaskStatusAnnotate = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_TASK_STATUS });
    let apiEndpoint = "change-task-status";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          let requestParams = {
            task_id: response.data.data.task_id
          };
          let requestPar = {
            category_id: response.data.data.category_id
          };
          dispatch(actions.getImageFromTaskIdWithOutLoader(requestParams, history));
          dispatch(actions.categoryTasksListWithLatestVersion(requestPar, history));
          dispatch({ type: CHANGE_TASK_STATUS_SUCCESS, message: response });
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: CHANGE_TASK_STATUS_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};



export const changeTaskStatusAnnotateReview = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_TASK_STATUS });
    let apiEndpoint = "change-task-status-review";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          NotificationManager.success(
            response.data.message,
            "Success",
            3000,
            null,
            null,
            ""
          );
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: CHANGE_TASK_STATUS_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};


export const changeTaskMainImgStatusAnnotate = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_TASK_MAINIMG_STATUS });
    let apiEndpoint = "change-PreProd-MainImage-Status";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          let requestPar = {
            task_id: response.data.data.task_id
          };

          let requestparams = {
              main_image_id: response.data.data.main_image_id,
              project_id: response.data.data.project_id,
          };
          dispatch(actions.getPreProdTaskMainImageWithOutLoader(requestparams, history));
          dispatch(actions.PreProdTasksMainImgListData(requestPar, history));
          dispatch({ type: CHANGE_TASK_MAINIMG_SUCCESS, message: response });
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: CHANGE_TASK_MAINIMG_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const PreProdTasksMainImgListData = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: TASKS_MAINIMG_LIST_START });
    let apiEndpoint = "task-main-images-list-annotate";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          dispatch({
            type: TASKS_MAINIMG_LIST_START_SUCCESS,
            taskMainImagesData: response,
          });
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: TASKS_MAINIMG_LIST_START_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const categoryTasksListWithLatestVersion = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: CATEGORY_TASKS_LIST_WITH_VERSIONIMG_START });
    let apiEndpoint = "category-tasks-latest-version-image";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          dispatch({
            type: CATEGORY_TASKS_LIST_WITH_VERSIONIMG_SUCCESS,
            categoryTasksData: response,
          });
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: CATEGORY_TASKS_LIST_WITH_VERSIONIMG_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const catTasksCompareImage = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: CATEGORY_TASKS_COMPARE_IMAGE });
    let apiEndpoint = "category-tasks-compare-image";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          dispatch({
            type: CATEGORY_TASKS_COMPARE_IMAGE_SUCCESS,
            categoryTasksData: response,
          });
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: CATEGORY_TASKS_COMPARE_IMAGE_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const catTasksCompareImagePreProd = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: CATEGORY_TASKS_COMPARE_IMAGE_PREPROD });
    let apiEndpoint = "category-tasks-compare-image-preprod";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          dispatch({
            type: CATEGORY_TASKS_COMPARE_IMAGE_PREPROD_SUCCESS,
            categoryTasksData: response,
          });
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: CATEGORY_TASKS_COMPARE_IMAGE_PREPROD_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const addNewAnnotateMainImages = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: TASK_ADD_NEW_ANNOTATE_MAIN_IMG });
    let apiEndpoint = "add-annotation-main-image";
    const token = localStorage.getItem("UserToken");
    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          // let requestParams = {
          //   task_id: response.data.data.task_id
          // };
          // dispatch(actions.getImageFromTaskId(requestParams, history));
          
          dispatch({
            type: TASK_ADD_NEW_ANNOTATE_MAIN_IMG_SUCCESS,
            message: response,
          });
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        if (!error.response.data.success) {
          dispatch({
            type: TASK_ADD_NEW_ANNOTATE_MAIN_IMG_ERROR,
            error: error.response.data.message,
          });
          NotificationManager.warning(
            error.response.data.message,
            "Warning",
            3000,
            null,
            null,
            ""
          );
        }
      });
  };
};

export const addNewAnnotateMainImagesReview = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: TASK_ADD_NEW_ANNOTATE_MAIN_IMG });
    let apiEndpoint = "add-annotation-main-image-review";
    const token = localStorage.getItem("UserToken");
    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          // let requestParams = {
          //   task_id: response.data.data.task_id
          // };
          // dispatch(actions.getImageFromTaskId(requestParams, history));
          
          dispatch({
            type: TASK_ADD_NEW_ANNOTATE_MAIN_IMG_SUCCESS,
            message: response,
          });
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        if (!error.response.data.success) {
          dispatch({
            type: TASK_ADD_NEW_ANNOTATE_MAIN_IMG_ERROR,
            error: error.response.data.message,
          });
          NotificationManager.warning(
            error.response.data.message,
            "Warning",
            3000,
            null,
            null,
            ""
          );
        }
      });
  };
};

