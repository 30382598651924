import { SET_UPLOAD_FILE, SET_UPLOAD_PROGRESS, SUCCESS_UPLOAD_FILE, FAILURE_UPLOAD_FILE, RETRY_UPLOAD_FILE, FAIL_UPLOAD_FILE, CLOSE_UPLOAD_FILE  } from "../actions";

import axios from "axios";
import { modifyFiles } from '../uploadFile/uploadFile.utils';
import { STATUS_UPLOAD } from '../../helpers/constants';

// const INIT_STATE = {
//   loading: false,
//   error: "",
// };

const INITIAL_STATE = {
  fileProgress: {
    // format will be like below
    // 1: {
    //   id: 1,
    //   file,
    //   progress: 0,
    //   cancelSource: source,
    //   status: 0,
    //   upload_status: 0,
    // },
  },
}

const fileProgressReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_UPLOAD_FILE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          ...modifyFiles(state.fileProgress, action.payload),
        },
      }

    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            progress: action.payload.progress,
          },
        },
      }

    case SUCCESS_UPLOAD_FILE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            status: 1,
            upload_status: STATUS_UPLOAD.success,
            resmsg: action.payload.msg, 
          },
        },
      }

    case FAIL_UPLOAD_FILE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            status: 1,
            upload_status: STATUS_UPLOAD.failed,
            progress: 0,
            resmsg: action.payload.msg, 
          },
        },
      }

    case FAILURE_UPLOAD_FILE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
            //status: 0,
            status: 1,
            upload_status: STATUS_UPLOAD.failed,
            progress: 0,
            resmsg: "Upload Canceled", 
          },
        },
      }

    case RETRY_UPLOAD_FILE:
      const CancelToken = axios.CancelToken
      const cancelSource = CancelToken.source()
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
            status: STATUS_UPLOAD.uploading,
            progress: 0,
            cancelSource,
          }
        }
      }
    
    case CLOSE_UPLOAD_FILE: return {
      ...state,
      fileProgress: {},
    }

    default:
      return state
  }
}

export default fileProgressReducer
