import {
  GET_PLANS_DATA
} from "../actions";
import config from "../../config/config";
import { NotificationManager } from "../../../src/components/common/react-notifications";
import axios from "axios";

export const getPlanList = (history) => {
  return (dispatch) => {
    let apiEndpoint = "get-plans";
    const token = localStorage.getItem("UserToken");
    axios
      .get(config.baseUrl + apiEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch({
          type: GET_PLANS_DATA,
          data: response.data,
        });
      })
      .catch((error) => {
        
      });
  };
};

export const putPlanList = (payload, history) => {
  return (dispatch) => {
    let apiEndpoint = "put-plans";
    const token = localStorage.getItem("UserToken");
    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        NotificationManager.success(
          "Plans Updated Successfully",
          "Success",
          3000,
          null,
          null,
          ""
        );
      })
      .catch((error) => {
        NotificationManager.warning(
          "Something Went Wrong",
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const cancelSubscription = (history, closePopup) => {
  return (dispatch) => {
    let apiEndpoint = "un-subscribe-client";
    const token = localStorage.getItem("UserToken");    
    axios
      .post(config.baseUrl + apiEndpoint, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTimeout(()=>{
          window.location.reload();
        },1500)
        closePopup(true)
        NotificationManager.success(
          "Plan Canceled successfully",
          "Success",
          3000,
          null,
          null,
          ""
        );
      })
      .catch((error) => {
        closePopup(true)
        NotificationManager.warning(
          "Something Went Wrong",
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};


