import {
  CREATE_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_ERROR,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,
  CREATE_PROJECT_DETAIL_SUCCESS,
  PROJECT_CATEGORY_LIST_START,
  PROJECT_CATEGORY_LIST_SUCCESS,
  PROJECT_CATEGORY_LIST_ERROR,
  CATEGORY_TASKS_LIST_START,
  CATEGORY_TASKS_LIST_SUCCESS,
  CATEGORY_TASKS_LIST_ERROR,
  UPLOAD_TASK_MAIN_IMAGE,
  UPLOAD_TASK_MAIN_IMAGE_SUCCESS,
  UPLOAD_TASK_MAIN_IMAGE_ERROR,
  DELETE_PROJECT_CATEGORY,
  DELETE_PROJECT_CATEGORY_SUCCESS,
  DELETE_PROJECT_CATEGORY_ERROR,
  PROJECT_CATEGORY_LIST_START_ADDUPD,
  PROJECT_CATEGORY_LIST_SUCCESS_ADDUPD,
  PROJECT_CATEGORY_LIST_ERROR_ADDUPD,
  PROJECT_CATEGORY_LIST_CLEAR,
  PROJCAT_LIST_START,
  PROJCAT_LIST_SUCCESS,
  PROJCAT_LIST_FAILED,
} from "../actions";
import config from "../../config/config";
import { NotificationManager } from "../../../src/components/common/react-notifications";
import axios from "axios";

import * as actions from "../../redux/actions";

export const createCategory = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: CREATE_CATEGORY });
    let apiEndpoint = "add-category";
    const token = localStorage.getItem("UserToken");
    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          dispatch(actions.getProjectDetails(history));
          dispatch({ type: CREATE_CATEGORY_SUCCESS, data: response });

          NotificationManager.success(
            response.data.message,
            "Success",
            3000,
            null,
            null,
            ""
          );
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: CREATE_CATEGORY_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};


export const clearReducerCategory = () => {
  return (dispatch) => {
    dispatch({
      type: PROJECT_CATEGORY_LIST_CLEAR,
      categoryData: [],
    });
  }
}

export const projectCategoryList = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: PROJECT_CATEGORY_LIST_START });
    let apiEndpoint = "category-list";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          dispatch({
            type: PROJECT_CATEGORY_LIST_SUCCESS,
            categoryData: response,
          });
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: PROJECT_CATEGORY_LIST_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const projectCategoryListAddUpdate = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: PROJECT_CATEGORY_LIST_START_ADDUPD });
    let apiEndpoint = "category-list";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          dispatch({
            type: PROJECT_CATEGORY_LIST_SUCCESS_ADDUPD,
            categoryData: response,
          });
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: PROJECT_CATEGORY_LIST_ERROR_ADDUPD,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const createProjectCategory = (
  payload,
  history,
  projCatListreqparams
) => {
  return (dispatch) => {
    dispatch({ type: CREATE_CATEGORY });
    let apiEndpoint = "add-category";
    const token = localStorage.getItem("UserToken");
    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          dispatch(actions.projectCategoryListAddUpdate(projCatListreqparams, history));
          dispatch(actions.projectCategoryListAddUpdate(projCatListreqparams, history));
          dispatch(actions.projectCategoryListAddUpdate(projCatListreqparams, history));
          dispatch({ type: CREATE_CATEGORY_SUCCESS, data: response });

          NotificationManager.success(
            response.data.message,
            "Success",
            3000,
            null,
            null,
            ""
          );
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: CREATE_CATEGORY_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const editProjectCategory = (payload, history,projCatListreqparams) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_CATEGORY });
    let apiEndpoint = "edit-category";
    const token = localStorage.getItem("UserToken");
    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          dispatch(actions.projectCategoryListAddUpdate(projCatListreqparams, history));
          dispatch({ type: UPDATE_CATEGORY_SUCCESS, data: response });

          NotificationManager.success(
            response.data.message,
            "Success",
            3000,
            null,
            null,
            ""
          );
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: UPDATE_CATEGORY_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};
export const categoryTasksList = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: CATEGORY_TASKS_LIST_START });
    let apiEndpoint = "category-tasks";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          dispatch({
            type: CATEGORY_TASKS_LIST_SUCCESS,
            categoryTasksData: response,
          });
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: CATEGORY_TASKS_LIST_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const uploadTaskMainImg = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: UPLOAD_TASK_MAIN_IMAGE });
    let apiEndpoint = "upload-task-main-image";
    const token = localStorage.getItem("UserToken");
    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          let params = {
            category_id: response.data.data.category_id,
            tableState: {
              page: 1,
              rowsPerPage: 6,
              searchText: "",
              sortBy: "id",
              sortDirection: "DESC",
            },
          };
          dispatch(actions.categoryTasksList(params, history));

          dispatch({
            type: UPLOAD_TASK_MAIN_IMAGE_SUCCESS,
            message: response,
          });
          NotificationManager.success(
            response.data.message,
            "Success",
            8000,
            null,
            null,
            ""
          );
        }
      })
      .catch(function (error) {
        if (!error.response.data.success) {
          dispatch({
            type: UPLOAD_TASK_MAIN_IMAGE_ERROR,
            error: error.response.data.message,
          });
          NotificationManager.warning(
            error.response.data.message,
            "Warning",
            8000,
            null,
            null,
            ""
          );
        }
      });
  };
};

export const deleteProjectCategory = (payload, history, projCatListreqparams) => {
  return (dispatch) => {
    dispatch({ type: DELETE_PROJECT_CATEGORY });
    let apiEndpoint = "delete-category";
    const token = localStorage.getItem("UserToken");
    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          console.log(projCatListreqparams, history);
          dispatch(actions.projectCategoryListAddUpdate(projCatListreqparams, history));
          dispatch(actions.projectCategoryListAddUpdate(projCatListreqparams, history));
          dispatch(actions.projectCategoryListAddUpdate(projCatListreqparams, history));
          dispatch(actions.projectCategoryListAddUpdate(projCatListreqparams, history));
          
          dispatch({ type: DELETE_PROJECT_CATEGORY_SUCCESS, data: response });
          NotificationManager.success(
            response.data.message,
            "Success",
            3000,
            null,
            null,
            ""
          );
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: DELETE_PROJECT_CATEGORY_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

export const projCategoryList = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: PROJCAT_LIST_START });
    let apiEndpoint = "project-category-list";
    const token = localStorage.getItem("UserToken");

    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          dispatch({
            type: PROJCAT_LIST_SUCCESS,
            categoryData: response,
          });
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: PROJCAT_LIST_FAILED,
          error: error.response.data.message,
        });
      });
  };
};