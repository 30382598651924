import { 
  GET_SUBSCRIBER_LIST_START,
  GET_SUBSCRIBER_LIST_SUCCESS,
  GET_SUBSCRIBER_LIST_FAILED, 
  BLOCK_SUBSCRIBER_START,
  BLOCK_SUBSCRIBER_SUCCESS,
  BLOCK_SUBSCRIBER_FAILED,
} from "../actions";

const INIT_STATE = {
  loading: false,
  error: "",
  SubscriberListData: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIBER_LIST_START:
      return { ...state, loading: true, error: "" };
    case GET_SUBSCRIBER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        type: action.type,
        SubscriberListData: action.message.data.data,
      };
    case GET_SUBSCRIBER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
      
    case BLOCK_SUBSCRIBER_START:
      return { ...state, type: action.type, error: "" };
    case BLOCK_SUBSCRIBER_SUCCESS:
      return { ...state,  change_subscriber: action.data};
    case BLOCK_SUBSCRIBER_FAILED:
      return {
        ...state,
        error: action.error,
      };
        
    default:
      return { ...state };
  }
};
