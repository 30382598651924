import {
	CHAT_GET_CONTACTS,
	CHAT_GET_CONTACTS_SUCCESS,
	CHAT_GET_CONTACTS_ERROR,
	CHAT_GET_CONVERSATIONS,
	CHAT_GET_CONVERSATIONS_SUCCESS,
	CHAT_GET_CONVERSATIONS_ERROR,
	CHAT_ADD_MESSAGE_TO_CONVERSATION,
	CHAT_CREATE_CONVERSATION,
	CHAT_SEARCH_CONTACT,
	CHAT_CHANGE_CONVERSATION,
	CHAT_PROJECT_LIST_START,
    CHAT_PROJECT_LIST_SUCCESS,
	CHAT_PROJECT_LIST_FAILED,
	GET_RECENT_CHAT_USERS_START,
    GET_RECENT_CHAT_USERS_SUCCESS,
	GET_RECENT_CHAT_USERS_FAILED,
	GET_USER_CHAT_START,
    GET_USER_CHAT_FAILED,
	GET_USER_CHAT_SUCCESS,
	ALL_MESSAGES,
	REMOVE_ALL_MESSAGES,
	CHAT_CREATE_GROUP,
	CHAT_CREATE_GROUP_SUCCESS,
	CHAT_CREATE_GROUP_ERROR,
	DELETE_GROUP_CHAT_START,
    DELETE_GROUP_CHAT_SUCCESS,
    DELETE_GROUP_CHAT_FAIL,
	DELETE_CHAT_USER_START,
	DELETE_CHAT_USER_SUCCESS,
	DELETE_CHAT_USER_FAIL,
} from '../actions';

import { updateObject } from "../../util/utility";

const INIT_STATE = {
	allContacts: null,
	contacts: null,
	conversations: null,
	error: '',
	searchKeyword: '',
	loadingContacts: false,
	loadingConversations: false,
	currentUser: null,
	selectedUser: null,
	selectedUserId: null,
	ChatProjectList: [],
	recentChatUsers: [],
	groupChatUsers: [],
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case CHAT_GET_CONTACTS:
			return { ...state, loadingContacts: false };

		case CHAT_GET_CONTACTS_SUCCESS:
			return { ...state, loadingContacts: true, allContacts: action.payload.contacts, contacts: action.payload.contacts, currentUser: action.payload.currentUser };

		case CHAT_GET_CONTACTS_ERROR:
			return { ...state, loadingContacts: false, error: action.payload };


		case CHAT_GET_CONVERSATIONS:
			return { ...state, loadingConversations: false };

		case CHAT_GET_CONVERSATIONS_SUCCESS:
			return { ...state, 
				loadingConversations: true, 
				conversations: action.payload.conversations, 
				selectedUserId:  action.payload.selectedUser 
			};

		case CHAT_GET_CONVERSATIONS_ERROR:
			return { ...state, loadingConversations: false, error: action.payload };

		case CHAT_CHANGE_CONVERSATION:
			return { ...state, selectedUser: state.allContacts.find(x => x.id === action.payload) };


		case CHAT_ADD_MESSAGE_TO_CONVERSATION:
			return { ...state };

		case CHAT_CREATE_CONVERSATION:
			return { ...state };

		case CHAT_SEARCH_CONTACT:
			if (action.payload === '') {
				return { ...state, contacts: state.allContacts };
			} else {
				const keyword = action.payload.toLowerCase();
				const searchedContacts = state.allContacts.filter((item) => item.name.toLowerCase().indexOf(keyword) > -1);
				return { ...state, contacts: searchedContacts }
			}

		case CHAT_PROJECT_LIST_START:
			return updateObject(state, { loading: true, error: null });
		case CHAT_PROJECT_LIST_SUCCESS:
			return updateObject(state, { loading: false, error: null,ChatProjectList: action.state.data});
		case CHAT_PROJECT_LIST_FAILED:
			return updateObject(state, { loading: false, error: action.error });

		 // get recent chat user
		case GET_RECENT_CHAT_USERS_START:
            return updateObject(state, { loading: true, error: null,type:action.type });
        case GET_RECENT_CHAT_USERS_SUCCESS:
			return updateObject(state, { loading: false, error: null,recentChatUsers: action.state.data.data,type:action.type});
        case GET_RECENT_CHAT_USERS_FAILED:
			return updateObject(state, { loading: false, error: action.error,type:action.type });

		case GET_USER_CHAT_START:
			return updateObject(state, { loading: true, error: null,type:'chat' });
		case GET_USER_CHAT_FAILED:
			return updateObject(state, { loading: false, error: null,type:'chat'});
		case GET_USER_CHAT_SUCCESS:
			return updateObject(state, { loading: false, error: action.error,UserChat: action.state.data,type:'chat' });
		case ALL_MESSAGES:
			return updateObject(state, { allmsgs: action.state });
		case REMOVE_ALL_MESSAGES:
			return updateObject(state, { allmsgs: action.state });

		case CHAT_CREATE_GROUP:
			return {
				...state,
				message: "",
			};
		case CHAT_CREATE_GROUP_SUCCESS:
			//console.log('action',action);
			return {
				...state,
				CreateGroup: action.data,
			};
		case CHAT_CREATE_GROUP_ERROR:
			return {
				...state,
				error: action.error,
			};

		case DELETE_GROUP_CHAT_START:
			return {
				...state,
				message: "",
			};
		case DELETE_GROUP_CHAT_SUCCESS:
			//console.log('action',action);
			return {
				...state,
				DeleteGroup: action.data,
			};
		case DELETE_GROUP_CHAT_FAIL:
			return {
				...state,
				error: action.error,
			};
		
		case DELETE_CHAT_USER_START:
			return {
				...state,
				message: "",
			};
		case DELETE_CHAT_USER_SUCCESS:
			//console.log('action',action);
			return {
				...state,
				DeleteChatUser: action.data,
			};
		case DELETE_CHAT_USER_FAIL:
			return {
				...state,
				error: action.error,
			};
				
		default: return { ...state };
	}
}
