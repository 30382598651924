import {
  GET_SETUP_APPROVAL,
  GET_SETUP_APPROVAL_SUCCESS,
  GET_SETUP_APPROVAL_ERROR,
  CREATE_PROJECT,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_ERROR,
  CREATE_PROJECT_DETAIL,
  CREATE_PROJECT_DETAIL_SUCCESS,
  CREATE_PROJECT_DETAIL_ERROR,
  GET_CUSTOM_ROLES,
  GET_CUSTOM_ROLES_SUCCESS,
  GET_CUSTOM_ROLES_ERROR,
  PROJECT_LIST_START,
  PROJECT_LIST_SUCCESS,
  SEARCH_LIST,
  PROJECT_LIST_ERROR,
  DELETE_PROJECT_TASK,
  DELETE_PROJECT_TASK_SUCCESS,
  DELETE_PROJECT_TASK_ERROR,
  EDIT_TASK_DETAIL,
  EDIT_TASK_DETAIL_SUCCESS,
  EDIT_TASK_DETAIL_ERROR,
  UPDATE_TASK_DETAIL,
  UPDATE_TASK_DETAIL_SUCCESS,
  UPDATE_TASK_DETAIL_ERROR,
  ADD_TASK_DETAIL,
  ADD_TASK_DETAIL_SUCCESS,
  ADD_TASK_DETAIL_ERROR,
  LIST_HEADING_DETAIL,
  LIST_HEADING_DETAIL_SUCCESS,
  LIST_HEADING_DETAIL_ERROR,
  CREATE_NEW_ROLES,
  CREATE_NEW_ROLES_SUCCESS,
  CREATE_NEW_ROLES_ERROR,
  GET_USER_MGMT_LIST,
  GET_USER_MGMT_LIST_SUCCESS,
  GET_USER_MGMT_LIST_ERROR,
  CREATE_NEW_USER_MGMT,
  CREATE_NEW_USER_MGMT_SUCCESS,
  CREATE_NEW_USER_MGMT_ERROR,
  DELETE_TEAM_MEMBER,
  DELETE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_ERROR,
  UPDATE_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_ERROR,
  SEARCH_SINGLE_MEMBER_DETAIL,
  SEARCH_SINGLE_MEMBER_DETAIL_SUCCESS,
  SEARCH_SINGLE_MEMBER_DETAIL_ERROR,
  INVITE_NEW_PROJECT_TEAM_MEMBER,
  INVITE_NEW_PROJECT_TEAM_MEMBER_SUCCESS,
  INVITE_NEW_PROJECT_TEAM_MEMBER_ERROR,
  GET_PROJECT_MEMBER,
  GET_PROJECT_MEMBER_SUCCESS,
  GET_PROJECT_MEMBER_ERROR,
  SELECT_ASSIGN_MEMBER,
  SELECT_ASSIGN_MEMBER_SUCCESS,
  SELECT_ASSIGN_MEMBER_ERROR,
  TASK_DETAIL_ADD_NEW_REFERENCE_IMG,
  TASK_DETAIL_ADD_NEW_REFERENCE_IMG_SUCCESS,
  TASK_DETAIL_ADD_NEW_REFERENCE_IMG_ERROR,
  DELETE_TASK_REFERENCE_IMAGE,
  DELETE_TASK_REFERENCE_IMAGE_SUCCESS,
  DELETE_TASK_REFERENCE_IMAGE_ERROR,
  GET_INTERNAL_TEAM_MEMBER_CUSTOM_ROLES,
  GET_INTERNAL_TEAM_MEMBER_CUSTOM_ROLES_SUCCESS,
  GET_INTERNAL_TEAM_MEMBER_CUSTOM_ROLES_ERROR,
  CHANGE_PROJECT_PHASE_PRODUCTION,
  CHANGE_PROJECT_PHASE_PRODUCTION_SUCCESS,
  CHANGE_PROJECT_PHASE_PRODUCTION_ERROR,
  ADD_UPDATE_ROLES_RIGHT,
  ADD_UPDATE_ROLES_RIGHT_SUCCESS,
  ADD_UPDATE_ROLES_RIGHT_ERROR,
  PHASEWISE_ROLE_MGMT_LIST,
  PHASEWISE_ROLE_MGMT_LIST_SUCCESS,
  PHASEWISE_ROLE_MGMT_LIST_ERROR,
  PHASEWISE_USER_MGMT_LIST,
  PHASEWISE_USER_MGMT_LIST_SUCCESS,
  PHASEWISE_USER_MGMT_LIST_ERROR,
  CHECK_USER_ROLE_RIGHT,
  CHECK_USER_ROLE_RIGHT_SUCCESS,
  CHECK_USER_ROLE_RIGHT_ERROR,
  DELETE_TASK_ATTACHED_FILE,
  DELETE_TASK_ATTACHED_FILE_SUCCESS,
  DELETE_TASK_ATTACHED_FILE_ERROR,
  SINGLE_PROJECT_DASHBOARD,
  SINGLE_PROJECT_DASHBOARD_SUCCESS,
  SINGLE_PROJECT_DASHBOARD_ERROR,
  CHANGE_SETUP_APPROVAL,
  CHANGE_SETUP_APPROVAL_SUCCESS,
  CHANGE_SETUP_APPROVAL_ERROR,
  PROJECT_ACCESS_LOG,
  PROJECT_ACCESS_LOG_SUCCESS,
  PROJECT_ACCESS_LOG_ERROR,
  RENAME_PROJECT_NAME,
  RENAME_PROJECT_NAME_SUCCESS,
  RENAME_PROJECT_NAME_ERROR,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_ERROR,
  ADDUPD_TASK_ASSIGNMEMBERS_START,
  ADDUPD_TASK_ASSIGNMEMBERS_SUCCESS,
  ADDUPD_TASK_ASSIGNMEMBERS_ERROR,
  DEL_PROJECT_MEMBER_START,
  DEL_PROJECT_MEMBER_SUCCESS,
  DEL_PROJECT_MEMBER_ERROR,
  DEL_ROLEMGMT_START,
  DEL_ROLEMGMT_SUCCESS,
  DEL_ROLEMGMT_ERROR,
  ARCHIVE_PROJECT_LIST_START,
  ARCHIVE_PROJECT_LIST_SUCCESS,
  ARCHIVE_PROJECT_LIST_ERROR,
  PROJ_LIST_START,
  PROJ_LIST_SUCCESS,
  PROJ_LIST_FAILED,
  ARCHIVE_PROJECT,
  ARCHIVE_PROJECT_SUCCESS,
  ARCHIVE_PROJECT_ERROR,
  PROJECT_FILE_MANAGER_LIST,
  PROJECT_FILE_MANAGER_LIST_SUCCESS,
  PROJECT_FILE_MANAGER_LIST_ERROR,
  PROJECT_UPLOAD_FILE,
  PROJECT_UPLOAD_FILE_SUCCESS,
  PROJECT_UPLOAD_FILE_ERROR,
  RENAME_PROJECT_MEMBER_START,
  RENAME_PROJECT_MEMBER_SUCCESS,
  RENAME_PROJECT_MEMBER_ERROR,
} from "../actions";

import { updateProjectObject } from "../../util/utility";

const INIT_STATE = {
  loading: false,
  loading_agc: false,
  categories: [],
  projectsListData: [],
  ProjectInfo: [],
  customRole: [],
  internalMemberCustomRole: [],
  rolesData: "",
  teamMemberData: "",
  updateTeamMemberDetail: "",
  DelTeamMember: "",
  userManagementList: [],
  userAccessRight: [],
  defaultPageSize: "",
  loading2: false,
  taskData: "",
  SingleMemberInfo: [],
  projectMembers: [],
  assignMembers: [],
  selectAssignMembers: [],
  single_project_dashboard: [],
  TaskDetail: [],
  Taskinfo: [],
  updateTaskDetail: "",
  updateTaskDetails: "",
  pageHeadingDetail: "",
  invite_member: "",
  roles_right: "",
  archiveProjectsListData: [],  
  error: "",
  searchList:{},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SETUP_APPROVAL:
      return { ...state, loading: false, error: "" };
    case GET_SETUP_APPROVAL_SUCCESS:
      let setupApprovalDetails = action.message.data.data.setup_approval;
      return {
        ...state,
        loading: false,
        type: action.type,
        categories: setupApprovalDetails,
      };
    case GET_SETUP_APPROVAL_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case CREATE_PROJECT:
      return {
        ...state,
        message: "",
        loading: true,
      };
    case CREATE_PROJECT_SUCCESS:
      let projectDetails = action.projectData.data.data.project_info;
      return {
        ...state,
        loading: false,
        projectData: projectDetails,
      };
    case CREATE_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        warningProjectData:'yes',
        error: action.error,
      };
    case CREATE_PROJECT_DETAIL:
      return {
        ...state,
        loading_agc: true,
        error: "",
      };
    case CREATE_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        loading_agc: false,
        type: action.type,
        ProjectInfo: action.message.data.data,
        defaultPageSize: action.message.data.data.category_info.length,
      };
    case CREATE_PROJECT_DETAIL_ERROR:
      return {
        ...state,
        loading_agc: false,
        error: "",
      };
    case GET_CUSTOM_ROLES:
      return { ...state, loading: false, error: "" };
    case GET_CUSTOM_ROLES_SUCCESS:
      let customRoles = action.message.data.data.roles;
      return {
        ...state,
        loading: false,
        type: action.type,
        customRole: customRoles,
      };
    case GET_CUSTOM_ROLES_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case CREATE_NEW_ROLES:
      return {
        ...state,
        message: "",
        loading: true,
      };
    case CREATE_NEW_ROLES_SUCCESS:
      //console.log('action',action);
      return {
        ...state,
        loading: false,
        rolesData: action.data,
      };
    case CREATE_NEW_ROLES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PROJECT_LIST_START:
      return updateProjectObject(state, { loading: true, error: null });
    case PROJECT_LIST_SUCCESS:
      return updateProjectObject(state, {
        loading: false,
        error: null,
        projectsListData: action.projectData.data.data,
      });
    case PROJECT_LIST_ERROR:
      return updateProjectObject(state, {
        loading: false,
        error: action.error,
      });
    case DELETE_PROJECT_TASK:
      return {
        ...state,
        message: "",
        loading2: true,
      };
    case DELETE_PROJECT_TASK_SUCCESS:
      //console.log('action',action);
      return {
        ...state,
        loading2: false,
        taskData: action.data,
      };
    case DELETE_PROJECT_TASK_ERROR:
      return {
        ...state,
        loading2: false,
        error: action.error,
      };
    case EDIT_TASK_DETAIL:
      return { ...state, loading2: true, error: "" };
    case EDIT_TASK_DETAIL_SUCCESS:
      //console.log('red:',action.data.data.data.task_info);
      let task_info = action.data.data.data.task_info;
      return {
        ...state,
        loading2: false,
        type: action.type,
        TaskDetail: task_info,
        updateTaskDetail: "yes",
        updateTaskDetails: "yes",
      };
    case EDIT_TASK_DETAIL_ERROR:
      return {
        ...state,
        loading2: false,
        error: "",
      };
    case UPDATE_TASK_DETAIL:
      return {
        ...state,
        message: "",
        updateTaskDetail: "no",
        updateTaskDetails: "no",
        loading: true,
      };
    case UPDATE_TASK_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        updateTaskDetail: action.data.data,
        updateTaskDetails: action.data.data,
      };
    case UPDATE_TASK_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        updateTaskDetail: "no",
        updateTaskDetails: "no",
        error: action.error,
      };
    case ADD_TASK_DETAIL:
      return {
        ...state,
        message: "",
        loading: true,
      };
    case ADD_TASK_DETAIL_SUCCESS:
      //console.log("action", action);
      return {
        ...state,
        loading: false,
        updateTaskDetail: action.data,
      };
    case ADD_TASK_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        updateTaskDetail: action.error,
      };

    case LIST_HEADING_DETAIL:
      return { ...state, loading: false, error: "" };
    case LIST_HEADING_DETAIL_SUCCESS:
      let pageHeadingDetail = action.message.data.data.project_info;
      return {
        ...state,
        loading: false,
        type: action.type,
        pageHeadingDetail: pageHeadingDetail,
      };
    case LIST_HEADING_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case GET_USER_MGMT_LIST:
      return { ...state, loading: false, error: "" };
    case GET_USER_MGMT_LIST_SUCCESS:
      let userManagementList = action.message.data.data.team_members;
      return {
        ...state,
        loading: false,
        type: action.type,
        userManagementList: userManagementList,
      };
    case GET_USER_MGMT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case CREATE_NEW_USER_MGMT:
      return {
        ...state,
        message: "",
        loading: true,
      };
    case CREATE_NEW_USER_MGMT_SUCCESS:
      //console.log("action", action);
      return {
        ...state,
        loading: false,
        teamMemberData: action.data,
      };
    case CREATE_NEW_USER_MGMT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DELETE_TEAM_MEMBER:
      return {
        ...state,
        message: "",
        loading: true,
      };
    case DELETE_TEAM_MEMBER_SUCCESS:
      //console.log('action',action);
      return {
        ...state,
        loading: false,
        DelTeamMember: action.data,
      };
    case DELETE_TEAM_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case UPDATE_TEAM_MEMBER:
      return {
        ...state,
        message: "",
        loading: true,
      };
    case UPDATE_TEAM_MEMBER_SUCCESS:
      //console.log("action", action);
      return {
        ...state,
        loading: false,
        updateTeamMemberDetail: action.data,
      };
    case UPDATE_TEAM_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SEARCH_SINGLE_MEMBER_DETAIL:
      return { ...state, loading: false, error: "" };
    case SEARCH_SINGLE_MEMBER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        SingleMemberInfo: action.message.data.data,
        invite_member: "",
        type: action.type,
        error: "",
      };
    case SEARCH_SINGLE_MEMBER_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        SingleMemberInfo: "",
        invite_member: "",
        error: action,
      };

    case INVITE_NEW_PROJECT_TEAM_MEMBER:
      return {
        ...state,
        message: "",
        loadingInvite: true,
      };
    case INVITE_NEW_PROJECT_TEAM_MEMBER_SUCCESS:
      //console.log("action", action);
      return {
        ...state,
        loadingInvite: false,
        invite_member: action.message,
        SingleMemberInfo: "",
      };
    case INVITE_NEW_PROJECT_TEAM_MEMBER_ERROR:
      //console.log("regerror", action);
      return {
        ...state,
        loadingInvite: false,
        error: action.error,
        invite_member: "",
        SingleMemberInfo: "",
      };
    case GET_PROJECT_MEMBER:
      return { ...state, loading: false, error: "" };
    case GET_PROJECT_MEMBER_SUCCESS:
      //console.log("action", action);
      let projectMemberList = action.message.data.data.project_member;
      return {
        ...state,
        loading: false,
        projectAdmin: action.message.data.data.project_Admin,
        projectMembers: projectMemberList,
        projectMembersAddDel: action.message.data.data.proj_member_add_delete,
      };
    case GET_PROJECT_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case SELECT_ASSIGN_MEMBER:
      return { ...state, loading: false, error: "" };
    case SELECT_ASSIGN_MEMBER_SUCCESS:
      //console.log("action", action);
      let assignMemberList = action.message.data.data.assign_member;
      return {
        ...state,
        loading: false,
        selectAssignMembers: assignMemberList,
      };
    case SELECT_ASSIGN_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case TASK_DETAIL_ADD_NEW_REFERENCE_IMG:
      return {
        ...state,
        message: "",
        loading: true,
      };
    case TASK_DETAIL_ADD_NEW_REFERENCE_IMG_SUCCESS:
      //console.log("action", action);
      return {
        ...state,
        loading: false,
        addNewReferenceImg: action.message.data.success,
      };
    case TASK_DETAIL_ADD_NEW_REFERENCE_IMG_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case DELETE_TASK_REFERENCE_IMAGE:
      return {
        ...state,
        message: "",
        loading: true,
      };
    case DELETE_TASK_REFERENCE_IMAGE_SUCCESS:
      //console.log('action',action);
      return {
        ...state,
        loading: false,
        delTaskRefimg: action.message,
      };
    case DELETE_TASK_REFERENCE_IMAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case GET_INTERNAL_TEAM_MEMBER_CUSTOM_ROLES:
      return { ...state, loading: false, error: "" };
    case GET_INTERNAL_TEAM_MEMBER_CUSTOM_ROLES_SUCCESS:
      let intMemCusRole = action.message.data.data.roles;
      return {
        ...state,
        loading: false,
        type: action.type,
        internalMemberCustomRole: intMemCusRole,
      };
    case GET_INTERNAL_TEAM_MEMBER_CUSTOM_ROLES_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case CHANGE_PROJECT_PHASE_PRODUCTION:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case CHANGE_PROJECT_PHASE_PRODUCTION_SUCCESS:
      return {
        ...state,
        loading: false,
        type: action.type,
      };
    case CHANGE_PROJECT_PHASE_PRODUCTION_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
      };
    
    case ADD_UPDATE_ROLES_RIGHT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ADD_UPDATE_ROLES_RIGHT_SUCCESS:
      //console.log(action);
      return {
        ...state,
        loading: false,
        roles_right: action.message.data,
      };
    case ADD_UPDATE_ROLES_RIGHT_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case PHASEWISE_ROLE_MGMT_LIST:
      return { ...state, loading: false, error: "" };
    case PHASEWISE_ROLE_MGMT_LIST_SUCCESS:
      let custRoles = action.message.data.data.roles;
      return {
        ...state,
        loading: false,
        type: action.type,
        customRole: custRoles,
      };
    case PHASEWISE_ROLE_MGMT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case PHASEWISE_USER_MGMT_LIST:
      return { ...state, loading: false, error: "" };
    case PHASEWISE_USER_MGMT_LIST_SUCCESS:
      let userMgmtList = action.message.data.data.team_members;
      return {
        ...state,
        loading: false,
        type: action.type,
        userManagementList: userMgmtList,
      };
    case PHASEWISE_USER_MGMT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
      };

      case CHECK_USER_ROLE_RIGHT:
        return { ...state, loading: false, error: "" };
      case CHECK_USER_ROLE_RIGHT_SUCCESS:
        let user_access_right = action.message.data.data.user_access_right;
        return {
          ...state,
          loading: false,
          type: action.type,
          userAccessRight: user_access_right,
        };
      case CHECK_USER_ROLE_RIGHT_ERROR:
        return {
          ...state,
          loading: false,
          error: "",
        };

    case DELETE_TASK_ATTACHED_FILE:
      return {
        ...state,
        message: "",
        loading: true,
      };
    case DELETE_TASK_ATTACHED_FILE_SUCCESS:
      //console.log('action',action);
      return {
        ...state,
        loading: false,
        delTaskAttachFile: action.message,
      };
    case DELETE_TASK_ATTACHED_FILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SINGLE_PROJECT_DASHBOARD:
      return { ...state, loading: false, error: "" };
    case SINGLE_PROJECT_DASHBOARD_SUCCESS:
      let project_dashboard = action.message.data.data.project_info;
      return {
        ...state,
        loading: false,
        single_project_dashboard: project_dashboard,
      };
    case SINGLE_PROJECT_DASHBOARD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case CHANGE_SETUP_APPROVAL:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case CHANGE_SETUP_APPROVAL_SUCCESS:
      return {
        ...state,
        loading: false,
        type: action.type,
        change_setup_approval: action.message.data.data.change_setup_approval,
      };
    case CHANGE_SETUP_APPROVAL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case PROJECT_ACCESS_LOG:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case PROJECT_ACCESS_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        type: action.type,
        project_access_log: action.message.data.data.access_logs,
      };
    case PROJECT_ACCESS_LOG_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case RENAME_PROJECT_NAME:
      return {
        ...state,
        message: "",
        loading: true,
      };
    case RENAME_PROJECT_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        projectData: action.data,
      };
    case RENAME_PROJECT_NAME_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    
    case DELETE_PROJECT:
    return {
      ...state,
      message: "",
      loading: true,
    };
    case DELETE_PROJECT_SUCCESS:
      //console.log('action',action);
      return {
        ...state,
        loading: false,
        delProjData: action.data,
      };
    case DELETE_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
    };

    case ARCHIVE_PROJECT:
    return {
      ...state,
      message: "",
      loading: true,
    };
    case ARCHIVE_PROJECT_SUCCESS:
      //console.log('action',action);
      return {
        ...state,
        loading: false,
        archiveProjData: action.data,
      };
    case ARCHIVE_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
    };

    case ADDUPD_TASK_ASSIGNMEMBERS_START:
      return { ...state, loading: true, error: "" };
    case ADDUPD_TASK_ASSIGNMEMBERS_SUCCESS:
      let taskInfo = action.data.data.data;
      return {
        ...state,
        loading: false,
        type: action.type,
        Taskinfo: taskInfo,
        //updateTaskDetail: "yes",
        //updateTaskDetails: "yes",
      };
    case ADDUPD_TASK_ASSIGNMEMBERS_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
      };
    
    case DEL_PROJECT_MEMBER_START:
    return {
      ...state,
      message: "",
      //loading: true,
    };
    case DEL_PROJECT_MEMBER_SUCCESS:
      //console.log('action',action);
      return {
        ...state,
        //loading: false,
        delProjMemberData: action.data,
      };
    case DEL_PROJECT_MEMBER_ERROR:
      return {
        ...state,
        //loading: false,
        error: action.error,
    };

    case DEL_ROLEMGMT_START:
    return {
      ...state,
      message: "",
      loading: true,
    };
    case DEL_ROLEMGMT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DEL_ROLEMGMT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
    };

    case ARCHIVE_PROJECT_LIST_START:
      return updateProjectObject(state, { loading: true, error: null });
    case ARCHIVE_PROJECT_LIST_SUCCESS:
      return updateProjectObject(state, {
        loading: false,
        error: null,
        archiveProjectsListData: action.projectData.data.data,
      });
    case ARCHIVE_PROJECT_LIST_ERROR:
      return updateProjectObject(state, {
        loading: false,
        error: action.error,
      });
      
    case PROJ_LIST_START:
      return updateProjectObject(state, { error: null });
    case PROJ_LIST_SUCCESS:
      return updateProjectObject(state, { error: null,projectList: action.state.data});
    case PROJ_LIST_FAILED:
      return updateProjectObject(state, { error: action.error });

    case PROJECT_FILE_MANAGER_LIST:
      return updateProjectObject(state, { loading: true, error: null });
    case PROJECT_FILE_MANAGER_LIST_SUCCESS:
      return updateProjectObject(state, {
        loading: false,
        error: null,
        projectFileList: action.projectData.data.data.project_info,
      });
    case PROJECT_FILE_MANAGER_LIST_ERROR:
      return updateProjectObject(state, {
        loading: false,
        error: action.error,
      });

    case PROJECT_UPLOAD_FILE:
      return {
        ...state,
        message: "",
        loading: true,
      };
    case PROJECT_UPLOAD_FILE_SUCCESS:
      //console.log("action", action);
      return {
        ...state,
        loading: false,
        UploadNewProjectFile: action.message.data,
      };
    case PROJECT_UPLOAD_FILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case RENAME_PROJECT_MEMBER_START:
      return {
        ...state,
        message: "",
      };
      case RENAME_PROJECT_MEMBER_SUCCESS:
        return {
          ...state,
          renameProjMemberData: action.data,
        };
      case RENAME_PROJECT_MEMBER_ERROR:
        return {
          ...state,
          error: action.error,
      };
      case SEARCH_LIST:
        return {
          ...state,
          searchList: action.searchData.data,
      };

      
      
    default:
      return { ...state };
  }
};
