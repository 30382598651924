/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1280;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" }
];

// export const firebaseConfig = {
//   apiKey: "AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg",
//   authDomain: "gogo-react-login.firebaseapp.com",
//   databaseURL: "https://gogo-react-login.firebaseio.com",
//   projectId: "gogo-react-login",
//   storageBucket: "gogo-react-login.appspot.com",
//   messagingSenderId: "216495999563"
// };

// Local web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyDO_tYn8ESgGi_SnlxWSBe-0OSFpefjn6M",
  authDomain: "collaboration-tool-af61d.firebaseapp.com",
  projectId: "collaboration-tool-af61d",
  databaseURL: "https://collaboration-tool-af61d-default-rtdb.firebaseio.com",
  storageBucket: "collaboration-tool-af61d.appspot.com",
  messagingSenderId: "377538707608",
  appId: "1:377538707608:web:a6f264f38629181cc910ed",
  measurementId: "G-F252GQ6NJE"
};

//Live our server[192] web app's Firebase configuration 
// export const firebaseConfig = {
//   apiKey: "AIzaSyBXistSsEqFYyH2s-tSjz5vKaUrZqCV5QI",
//   authDomain: "collaborationlive-ourserver.firebaseapp.com",
//   databaseURL: "https://collaborationlive-ourserver-default-rtdb.firebaseio.com",
//   projectId: "collaborationlive-ourserver",
//   storageBucket: "collaborationlive-ourserver.appspot.com",
//   messagingSenderId: "1060167192541",
//   appId: "1:1060167192541:web:18bc8014a4ed6eab68b034"
// };

// Live [54 server] web app's Firebase configuration 
// export const firebaseConfig = {
//   apiKey: "AIzaSyCUQcfRjuL_MVWhsPkC09itxbgudKh51Jk",
//   authDomain: "collaboration-live.firebaseapp.com",
//   databaseURL: "https://collaboration-live-default-rtdb.firebaseio.com",
//   projectId: "collaboration-live",
//   storageBucket: "collaboration-live.appspot.com",
//   messagingSenderId: "694025954202",
//   appId: "1:694025954202:web:ac90083756c3ef804f1023"
// };

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
// let color = localStorage.getItem("__theme_color");
// if(color == "light.blue" || color == "dark.red"){
//   localStorage.setItem("__theme_color", "dark.blue");
// }

export const themeColorStorageKey="__theme_color"
export const isMultiColorActive = true;
export const defaultColor = "light.blue";
export const isDarkSwitchActive = true;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "rounded";
export const isDemo = true;
