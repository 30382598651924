import {
  CREATE_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_ERROR,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,
  PROJECT_CATEGORY_LIST_START,
  PROJECT_CATEGORY_LIST_SUCCESS,
  PROJECT_CATEGORY_LIST_ERROR,
  CATEGORY_TASKS_LIST_START,
  CATEGORY_TASKS_LIST_SUCCESS,
  CATEGORY_TASKS_LIST_ERROR,
  UPLOAD_TASK_MAIN_IMAGE,
  UPLOAD_TASK_MAIN_IMAGE_SUCCESS,
  UPLOAD_TASK_MAIN_IMAGE_ERROR,
  DELETE_PROJECT_CATEGORY,
  DELETE_PROJECT_CATEGORY_SUCCESS,
  DELETE_PROJECT_CATEGORY_ERROR,
  PROJECT_CATEGORY_LIST_START_ADDUPD,
  PROJECT_CATEGORY_LIST_SUCCESS_ADDUPD,
  PROJECT_CATEGORY_LIST_ERROR_ADDUPD,
  PROJCAT_LIST_START,
  PROJCAT_LIST_SUCCESS,
  PROJCAT_LIST_FAILED,
  PROJECT_CATEGORY_LIST_CLEAR
} from "../actions";

import { updateObject } from "../../util/utility";

const INIT_STATE = {
  loading1: false,
  categoryData: "",
  projCategoryListData: [],
  projCatListData: [],
  categoryTasksListData: [],
  error: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_CATEGORY:
      return {
        ...state,
        message: "",
        //loading1: true,
      };
    case CREATE_CATEGORY_SUCCESS:
      //console.log('action',action);
      return {
        ...state,
        //loading1: false,
        categoryData: action.data,
      };
    case CREATE_CATEGORY_ERROR:
      return {
        ...state,
        //loading1: false,
        error: action.error,
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        message: "",
        //loading1: true,
      };
    case UPDATE_CATEGORY_SUCCESS:
      //console.log('action',action);
      return {
        ...state,
        //loading1: false,
        categoryData: action.data,
      };
    case UPDATE_CATEGORY_ERROR:
      return {
        ...state,
        //loading1: false,
        error: action.error,
      };
    case PROJECT_CATEGORY_LIST_START:
      return updateObject(state, { loading1: true, error: null });
    case PROJECT_CATEGORY_LIST_SUCCESS:
      return updateObject(state, {
        loading1: false,
        error: null,
        projCategoryListData: action.categoryData.data.data,
      });
    case PROJECT_CATEGORY_LIST_CLEAR:
      return updateObject(state, {
        loading1: false,
        error: null,
        projCategoryListData: [],
        projCatListData: []
      });
    case PROJECT_CATEGORY_LIST_ERROR:
      return updateObject(state, {
        loading1: false,
        error: action.error,
      });

    case CATEGORY_TASKS_LIST_START:
      return updateObject(state, { loading1: true, error: null });
    case CATEGORY_TASKS_LIST_SUCCESS:
      // console.log("action", action.categoryTasksData.data.data);
      return updateObject(state, {
        loading1: false,
        error: null,
        categoryTasksListData: action.categoryTasksData.data.data,
      });
    case CATEGORY_TASKS_LIST_ERROR:
      return updateObject(state, {
        loading1: false,
        error: action.error,
      });
    case UPLOAD_TASK_MAIN_IMAGE:
      return {
        ...state,
        message: "",
        loading1: true,
      };
    case UPLOAD_TASK_MAIN_IMAGE_SUCCESS:
      return {
        ...state,
        loading1: false,
        uploadMainImg: action.message.data.success,
      };
    case UPLOAD_TASK_MAIN_IMAGE_ERROR:
      return {
        ...state,
        loading1: false,
        uploadMainImg: "",
        error: action.error,
      };

    case DELETE_PROJECT_CATEGORY:
      return {
        ...state,
        message: "",
        loading2: true,
      };
    case DELETE_PROJECT_CATEGORY_SUCCESS:
      //console.log('action',action);
      return {
        ...state,
        loading2: false,
        delCatData: action.data,
      };
    case DELETE_PROJECT_CATEGORY_ERROR:
      return {
        ...state,
        loading2: false,
        error: action.error,
      };

    case PROJECT_CATEGORY_LIST_START_ADDUPD:
      return updateObject(state, { error: null });
    case PROJECT_CATEGORY_LIST_SUCCESS_ADDUPD:
      return updateObject(state, {
        error: null,
        projCatListData: action.categoryData.data.data,
      });
    case PROJECT_CATEGORY_LIST_ERROR_ADDUPD:
      return updateObject(state, {
        error: action.error,
      });

    case PROJCAT_LIST_START:
      return updateObject(state, { loading1: true, error: null });
    case PROJCAT_LIST_SUCCESS:
      return updateObject(state, {
        loading1: false,
        error: null,
        prjCatListData: action.categoryData.data.data,
      });
    case PROJCAT_LIST_FAILED:
      return updateObject(state, {
        loading1: false,
        error: action.error,
      });

    default:
      return { ...state };
  }
};
