/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const CHECK_TOKEN = "CHECK_TOKEN";
export const CHECK_TOKEN_SUCCESS = "CHECK_TOKEN_SUCCESS";
export const CHECK_TOKEN_ERROR = "CHECK_TOKEN_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* TODOAPP*/
export const TODO_GET_LIST = "TODO_GET_LIST";
export const TODO_GET_LIST_SUCCESS = "TODO_GET_LIST_SUCCESS";
export const TODO_GET_LIST_ERROR = "TODO_GET_LIST_ERROR";
export const TODO_GET_LIST_WITH_FILTER = "TODO_GET_LIST_WITH_FILTER";
export const TODO_GET_LIST_WITH_ORDER = "TODO_GET_LIST_WITH_ORDER";
export const TODO_GET_LIST_SEARCH = "TODO_GET_LIST_SEARCH";
export const TODO_ADD_ITEM = "TODO_ADD_ITEM";
export const TODO_ADD_ITEM_SUCCESS = "TODO_ADD_ITEM_SUCCESS";
export const TODO_ADD_ITEM_ERROR = "TODO_ADD_ITEM_ERROR";
export const TODO_SELECTED_ITEMS_CHANGE = "TODO_SELECTED_ITEMS_CHANGE";

/* CHAT APP*/
export const CHAT_GET_CONTACTS = "CHAT_GET_CONTACTS";
export const CHAT_GET_CONTACTS_SUCCESS = "CHAT_GET_CONTACTS_SUCCESS";
export const CHAT_GET_CONTACTS_ERROR = "CHAT_GET_CONTACTS_ERROR";
export const CHAT_GET_CONVERSATIONS = "CHAT_GET_CONVERSATIONS";
export const CHAT_GET_CONVERSATIONS_SUCCESS = "CHAT_GET_CONVERSATIONS_SUCCESS";
export const CHAT_GET_CONVERSATIONS_ERROR = "CHAT_GET_CONVERSATIONS_ERROR";
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  "CHAT_ADD_MESSAGE_TO_CONVERSATION";
export const CHAT_CREATE_CONVERSATION = "CHAT_CREATE_CONVERSATION";
export const CHAT_SEARCH_CONTACT = "CHAT_SEARCH_CONTACT";
export const CHAT_CHANGE_CONVERSATION = "CHAT_CHANGE_CONVERSATION";
export const CHAT_CREATE_GROUP = "CHAT_CREATE_GROUP";
export const CHAT_CREATE_GROUP_SUCCESS = "CHAT_CREATE_GROUP_SUCCESS";
export const CHAT_CREATE_GROUP_ERROR = "CHAT_CREATE_GROUP_ERROR";

/* SURVEY LIST APP*/
export const SURVEY_LIST_GET_LIST = "SURVEY_LIST_GET_LIST";
export const SURVEY_LIST_GET_LIST_SUCCESS = "SURVEY_LIST_GET_LIST_SUCCESS";
export const SURVEY_LIST_GET_LIST_ERROR = "SURVEY_LIST_GET_LIST_ERROR";
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  "SURVEY_LIST_GET_LIST_WITH_FILTER";
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  "SURVEY_LIST_GET_LIST_WITH_ORDER";
export const SURVEY_LIST_GET_LIST_SEARCH = "SURVEY_LIST_GET_LIST_SEARCH";
export const SURVEY_LIST_ADD_ITEM = "SURVEY_LIST_ADD_ITEM";
export const SURVEY_LIST_ADD_ITEM_SUCCESS = "SURVEY_LIST_ADD_ITEM_SUCCESS";
export const SURVEY_LIST_ADD_ITEM_ERROR = "SURVEY_LIST_ADD_ITEM_ERROR";
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  "SURVEY_LIST_SELECTED_ITEMS_CHANGE";

/* SURVEY DETAIL APP*/
export const SURVEY_GET_DETAILS = "SURVEY_GET_DETAILS";
export const SURVEY_GET_DETAILS_SUCCESS = "SURVEY_GET_DETAILS_SUCCESS";
export const SURVEY_GET_DETAILS_ERROR = "SURVEY_GET_DETAILS_ERROR";
export const SURVEY_DELETE_QUESTION = "SURVEY_DELETE_QUESTION";
export const SURVEY_SAVE = "SURVEY_SAVE";

/* USER PROFILE */
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

/* UPDATE USER PROFILE */
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

/* UPLOAD IMAGE PROFILE */
export const UPLOAD_AVTAR = "UPLOAD_AVTAR";
export const UPLOAD_AVTAR_SUCCESS = "UPLOAD_AVTAR_SUCCESS";
export const UPLOAD_AVTAR_ERROR = "UPLOAD_AVTAR_ERROR";

/* PROJECT */
export const GET_SETUP_APPROVAL = "GET_SETUP_APPROVAL";
export const GET_SETUP_APPROVAL_SUCCESS = "GET_SETUP_APPROVAL_SUCCESS";
export const GET_SETUP_APPROVAL_ERROR = "GET_SETUP_APPROVAL_ERROR";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_ERROR = "CREATE_PROJECT_ERROR";
export const CREATE_PROJECT_DETAIL = "CREATE_PROJECT_DETAIL";
export const CREATE_PROJECT_DETAIL_SUCCESS = "CREATE_PROJECT_DETAIL_SUCCESS";
export const CREATE_PROJECT_DETAIL_ERROR = "CREATE_PROJECT_DETAIL_ERROR";
export const PROJECT_LIST_START = "PROJECT_LIST_START";
export const PROJECT_LIST_SUCCESS = "PROJECT_LIST_SUCCESS";
export const PROJECT_LIST_ERROR = "PROJECT_LIST_ERROR";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_ERROR = "CREATE_CATEGORY_ERROR";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_ERROR = "UPDATE_CATEGORY_ERROR";
export const DELETE_PROJECT_TASK = "DELETE_PROJECT_TASK";
export const DELETE_PROJECT_TASK_SUCCESS = "DELETE_PROJECT_TASK_SUCCESS";
export const DELETE_PROJECT_TASK_ERROR = "DELETE_PROJECT_TASK_ERROR";

export const EDIT_TASK_DETAIL = "EDIT_TASK_DETAIL";
export const EDIT_TASK_DETAIL_SUCCESS = "EDIT_TASK_DETAIL_SUCCESS";
export const EDIT_TASK_DETAIL_ERROR = "EDIT_TASK_DETAIL_ERROR";

export const UPDATE_TASK_DETAIL = "UPDATE_TASK_DETAIL";
export const UPDATE_TASK_DETAIL_SUCCESS = "UPDATE_TASK_DETAIL_SUCCESS";
export const UPDATE_TASK_DETAIL_ERROR = "UPDATE_TASK_DETAIL_ERROR";

export const ADD_TASK_DETAIL = "ADD_TASK_DETAIL";
export const ADD_TASK_DETAIL_SUCCESS = "ADD_TASK_DETAIL_SUCCESS";
export const ADD_TASK_DETAIL_ERROR = "ADD_TASK_DETAIL_ERROR";

export const PROJECT_CATEGORY_LIST_START = "PROJECT_CATEGORY_LIST_START";
export const PROJECT_CATEGORY_LIST_SUCCESS = "PROJECT_CATEGORY_LIST_SUCCESS";
export const PROJECT_CATEGORY_LIST_ERROR = "PROJECT_CATEGORY_LIST_ERROR";

export const PROJECT_CATEGORY_LIST_START_ADDUPD = "PROJECT_CATEGORY_LIST_START_ADDUPD";
export const PROJECT_CATEGORY_LIST_SUCCESS_ADDUPD = "PROJECT_CATEGORY_LIST_SUCCESS_ADDUPD";
export const PROJECT_CATEGORY_LIST_ERROR_ADDUPD = "PROJECT_CATEGORY_LIST_ERROR_ADDUPD";

export const LIST_HEADING_DETAIL = "LIST_HEADING_DETAIL";
export const LIST_HEADING_DETAIL_SUCCESS = "LIST_HEADING_DETAIL_SUCCESS";
export const LIST_HEADING_DETAIL_ERROR = "LIST_HEADING_DETAIL_ERROR";

export const CATEGORY_TASKS_LIST_START = "CATEGORY_TASKS_LIST_START";
export const CATEGORY_TASKS_LIST_SUCCESS = "CATEGORY_TASKS_LIST_SUCCESS";
export const CATEGORY_TASKS_LIST_ERROR = "CATEGORY_TASKS_LIST_ERROR";

/* Role management */
export const GET_CUSTOM_ROLES = "GET_CUSTOM_ROLES";
export const GET_CUSTOM_ROLES_SUCCESS = "GET_CUSTOM_ROLES_SUCCESS";
export const GET_CUSTOM_ROLES_ERROR = "GET_CUSTOM_ROLES_ERROR";
export const CREATE_NEW_ROLES = "CREATE_NEW_ROLES";
export const CREATE_NEW_ROLES_SUCCESS = "CREATE_NEW_ROLES_SUCCESS";
export const CREATE_NEW_ROLES_ERROR = "CREATE_NEW_ROLES_ERROR";

/* User management */
export const GET_USER_MGMT_LIST = "GET_USER_MGMT_LIST";
export const GET_USER_MGMT_LIST_SUCCESS = "GET_USER_MGMT_LIST_SUCCESS";
export const GET_USER_MGMT_LIST_ERROR = "GET_USER_MGMT_LIST_ERROR";
export const CREATE_NEW_USER_MGMT = "CREATE_NEW_USER_MGMT";
export const CREATE_NEW_USER_MGMT_SUCCESS = "CREATE_NEW_USER_MGMT_SUCCESS";
export const CREATE_NEW_USER_MGMT_ERROR = "CREATE_NEW_USER_MGMT_ERROR";
export const DELETE_TEAM_MEMBER = "DELETE_TEAM_MEMBER";
export const DELETE_TEAM_MEMBER_SUCCESS = "DELETE_TEAM_MEMBER_SUCCESS";
export const DELETE_TEAM_MEMBER_ERROR = "DELETE_TEAM_MEMBER_ERROR";
export const UPDATE_TEAM_MEMBER = "UPDATE_TEAM_MEMBER";
export const UPDATE_TEAM_MEMBER_SUCCESS = "UPDATE_TEAM_MEMBER_SUCCESS";
export const UPDATE_TEAM_MEMBER_ERROR = "UPDATE_TEAM_MEMBER_ERROR";
export const GET_SINGLE_MEMBER_DETAIL = "GET_SINGLE_MEMBER_DETAIL";
export const GET_SINGLE_MEMBER_DETAIL_SUCCESS =
  "GET_SINGLE_MEMBER_DETAIL_SUCCESS";
export const GET_SINGLE_MEMBER_DETAIL_ERROR = "GET_SINGLE_MEMBER_DETAIL_ERROR";
export const REGISTER_TEAM_MEMBER = "REGISTER_TEAM_MEMBER";
export const REGISTER_TEAM_MEMBER_SUCCESS = "REGISTER_TEAM_MEMBER_SUCCESS";
export const REGISTER_TEAM_MEMBER_ERROR = "REGISTER_TEAM_MEMBER_ERROR";

/* MEMBER PROFILE */
export const GET_MEMBER = "GET_MEMBER";
export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
export const GET_MEMBER_ERROR = "GET_MEMBER_ERROR";

/* Invite New Project Members */
export const SEARCH_SINGLE_MEMBER_DETAIL = "SEARCH_SINGLE_MEMBER_DETAIL";
export const SEARCH_SINGLE_MEMBER_DETAIL_SUCCESS =
  "SEARCH_SINGLE_MEMBER_DETAIL_SUCCESS";
export const SEARCH_SINGLE_MEMBER_DETAIL_ERROR =
  "SEARCH_SINGLE_MEMBER_DETAIL_ERROR";
export const INVITE_NEW_PROJECT_TEAM_MEMBER = "INVITE_NEW_PROJECT_TEAM_MEMBER";
export const INVITE_NEW_PROJECT_TEAM_MEMBER_SUCCESS =
  "INVITE_NEW_PROJECT_TEAM_MEMBER_SUCCESS";
export const INVITE_NEW_PROJECT_TEAM_MEMBER_ERROR =
  "INVITE_NEW_PROJECT_TEAM_MEMBER_ERROR";

/* Project Members */
export const GET_PROJECT_MEMBER = "GET_PROJECT_MEMBER";
export const GET_PROJECT_MEMBER_SUCCESS = "GET_PROJECT_MEMBER_SUCCESS";
export const GET_PROJECT_MEMBER_ERROR = "GET_PROJECT_MEMBER_ERROR";
export const SELECT_ASSIGN_MEMBER = "SELECT_ASSIGN_MEMBER";
export const SELECT_ASSIGN_MEMBER_SUCCESS = "SELECT_ASSIGN_MEMBER_SUCCESS";
export const SELECT_ASSIGN_MEMBER_ERROR = "SELECT_ASSIGN_MEMBER_ERROR";

export const TASK_DETAIL_ADD_NEW_REFERENCE_IMG =
  "TASK_DETAIL_ADD_NEW_REFERENCE_IMG";
export const TASK_DETAIL_ADD_NEW_REFERENCE_IMG_SUCCESS =
  "TASK_DETAIL_ADD_NEW_REFERENCE_IMG_SUCCESS";
export const TASK_DETAIL_ADD_NEW_REFERENCE_IMG_ERROR =
  "TASK_DETAIL_ADD_NEW_REFERENCE_IMG_ERROR";

export const DELETE_TASK_REFERENCE_IMAGE = "DELETE_TASK_REFERENCE_IMAGE";
export const DELETE_TASK_REFERENCE_IMAGE_SUCCESS =
  "DELETE_TASK_REFERENCE_IMAGE_SUCCESS";
export const DELETE_TASK_REFERENCE_IMAGE_ERROR =
  "DELETE_TASK_REFERENCE_IMAGE_ERROR";

export const UPLOAD_TASK_MAIN_IMAGE = "UPLOAD_TASK_MAIN_IMAGE";
export const UPLOAD_TASK_MAIN_IMAGE_SUCCESS = "UPLOAD_TASK_MAIN_IMAGE_SUCCESS";
export const UPLOAD_TASK_MAIN_IMAGE_ERROR = "UPLOAD_TASK_MAIN_IMAGE_ERROR";

export const GET_INTERNAL_TEAM_MEMBER_CUSTOM_ROLES =
  "GET_INTERNAL_TEAM_MEMBER_CUSTOM_ROLES";
export const GET_INTERNAL_TEAM_MEMBER_CUSTOM_ROLES_SUCCESS =
  "GET_INTERNAL_TEAM_MEMBER_CUSTOM_ROLES_SUCCESS";
export const GET_INTERNAL_TEAM_MEMBER_CUSTOM_ROLES_ERROR =
  "GET_INTERNAL_TEAM_MEMBER_CUSTOM_ROLES_ERROR";

export const TASK_ADD_NEW_MAIN_IMG = "TASK_ADD_NEW_MAIN_IMG";
export const TASK_ADD_NEW_MAIN_IMG_SUCCESS = "TASK_ADD_NEW_MAIN_IMG_SUCCESS";
export const TASK_ADD_NEW_MAIN_IMG_ERROR = "TASK_ADD_NEW_MAIN_IMG_ERROR";

export const GET_TASK_MAIN_IMAGES = "GET_TASK_MAIN_IMAGES";
export const GET_TASK_MAIN_IMAGES_SUCCESS = "GET_TASK_MAIN_IMAGES_SUCCESS";
export const GET_TASK_MAIN_IMAGES_ERROR = "GET_TASK_MAIN_IMAGES_ERROR";

export const CHANGE_PROJECT_PHASE_PRODUCTION =
  "CHANGE_PROJECT_PHASE_PRODUCTION";
export const CHANGE_PROJECT_PHASE_PRODUCTION_SUCCESS =
  "CHANGE_PROJECT_PHASE_PRODUCTION_SUCCESS";
export const CHANGE_PROJECT_PHASE_PRODUCTION_ERROR =
  "CHANGE_PROJECT_PHASE_PRODUCTION_ERROR";

export const UPLOAD_PROFILE_PHOTO = "UPLOAD_PROFILE_PHOTO";
export const UPLOAD_PROFILE_PHOTO_SUCCESS = "UPLOAD_PROFILE_PHOTO_SUCCESS";
export const UPLOAD_PROFILE_PHOTO_ERROR = "UPLOAD_PROFILE_PHOTO_ERROR";

export const ADD_UPDATE_ROLES_RIGHT = "ADD_UPDATE_ROLES_RIGHT";
export const ADD_UPDATE_ROLES_RIGHT_SUCCESS = "ADD_UPDATE_ROLES_RIGHT_SUCCESS";
export const ADD_UPDATE_ROLES_RIGHT_ERROR = "ADD_UPDATE_ROLES_RIGHT_ERROR";

export const PHASEWISE_ROLE_MGMT_LIST = "PHASEWISE_ROLE_MGMT_LIST";
export const PHASEWISE_ROLE_MGMT_LIST_SUCCESS = "PHASEWISE_ROLE_MGMT_LIST_SUCCESS";
export const PHASEWISE_ROLE_MGMT_LIST_ERROR = "PHASEWISE_ROLE_MGMT_LIST_ERROR";

export const PHASEWISE_USER_MGMT_LIST = "PHASEWISE_USER_MGMT_LIST";
export const PHASEWISE_USER_MGMT_LIST_SUCCESS = "PHASEWISE_USER_MGMT_LIST_SUCCESS";
export const PHASEWISE_USER_MGMT_LIST_ERROR = "PHASEWISE_USER_MGMT_LIST_ERROR";

export const CHECK_USER_ROLE_RIGHT = "CHECK_USER_ROLE_RIGHT";
export const CHECK_USER_ROLE_RIGHT_SUCCESS = "CHECK_USER_ROLE_RIGHT_SUCCESS";
export const CHECK_USER_ROLE_RIGHT_ERROR = "CHECK_USER_ROLE_RIGHT_ERROR";

export const DELETE_TASK_ATTACHED_FILE = "DELETE_TASK_ATTACHED_FILE";
export const DELETE_TASK_ATTACHED_FILE_SUCCESS = "DELETE_TASK_ATTACHED_FILE_SUCCESS";
export const DELETE_TASK_ATTACHED_FILE_ERROR = "DELETE_TASK_ATTACHED_FILE_ERROR";

export const DELETE_PROJECT_CATEGORY = "DELETE_PROJECT_CATEGORY";
export const DELETE_PROJECT_CATEGORY_SUCCESS = "DELETE_PROJECT_CATEGORY_SUCCESS";
export const DELETE_PROJECT_CATEGORY_ERROR = "DELETE_PROJECT_CATEGORY_ERROR";

export const SINGLE_PROJECT_DASHBOARD = "SINGLE_PROJECT_DASHBOARD";
export const SINGLE_PROJECT_DASHBOARD_SUCCESS = "SINGLE_PROJECT_DASHBOARD_SUCCESS";
export const SINGLE_PROJECT_DASHBOARD_ERROR = "SINGLE_PROJECT_DASHBOARD_ERROR";

export const CHANGE_SETUP_APPROVAL = "CHANGE_SETUP_APPROVAL";
export const CHANGE_SETUP_APPROVAL_SUCCESS = "CHANGE_SETUP_APPROVAL_SUCCESS";
export const CHANGE_SETUP_APPROVAL_ERROR = "CHANGE_SETUP_APPROVAL_ERROR";

export const PROJECT_ACCESS_LOG = "PROJECT_ACCESS_LOG";
export const PROJECT_ACCESS_LOG_SUCCESS = "PROJECT_ACCESS_LOG_SUCCESS";
export const PROJECT_ACCESS_LOG_ERROR = "PROJECT_ACCESS_LOG_ERROR";

export const TASK_ADD_NEW_PROD_MAIN_IMG = "TASK_ADD_NEW_PROD_MAIN_IMG";
export const TASK_ADD_NEW_PROD_MAIN_IMG_SUCCESS = "TASK_ADD_NEW_PROD_MAIN_IMG_SUCCESS";
export const TASK_ADD_NEW_PROD_MAIN_IMG_ERROR = "TASK_ADD_NEW_PROD_MAIN_IMG_ERROR";

export const GET_TASK_IMAGE = "GET_TASK_IMAGE";
export const GET_TASK_IMAGE_SUCCESS = "GET_TASK_IMAGE_SUCCESS";
export const GET_TASK_IMAGE_ERROR = "GET_TASK_IMAGE_ERROR";

export const ADD_VERSION_IMAGE = "ADD_VERSION_IMAGE";
export const ADD_VERSION_IMAGE_SUCCESS = "ADD_VERSION_IMAGE_SUCCESS";
export const ADD_VERSION_IMAGE_ERROR = "ADD_VERSION_IMAGE_ERROR";

export const DELETE_TASK_MAIN_IMAGE = "DELETE_TASK_MAIN_IMAGE";
export const DELETE_TASK_MAIN_IMAGE_SUCCESS = "DELETE_TASK_MAIN_IMAGE_SUCCESS";
export const DELETE_TASK_MAIN_IMAGE_ERROR = "DELETE_TASK_MAIN_IMAGE_ERROR";

export const GET_PREPROD_TASK_IMAGE = "GET_PREPROD_TASK_IMAGE";
export const GET_PREPROD_TASK_IMAGE_SUCCESS = "GET_PREPROD_TASK_IMAGE_SUCCESS";
export const GET_PREPROD_TASK_IMAGE_ERROR = "GET_PREPROD_TASK_IMAGE_ERROR";

export const SHARE_IMG_LINK = "SHARE_IMG_LINK";
export const SHARE_IMG_LINK_SUCCESS = "SHARE_IMG_LINK_SUCCESS";
export const SHARE_IMG_LINK_ERROR = "SHARE_IMG_LINK_ERROR";

export const ADD_TASK_COMMENT = "ADD_TASK_COMMENT";
export const ADD_TASK_COMMENT_SUCCESS = "ADD_TASK_COMMENT_SUCCESS";
export const ADD_TASK_COMMENT_ERROR = "ADD_TASK_COMMENT_ERROR";

export const DELETE_TASK_COMMENT = "DELETE_TASK_COMMENT";
export const DELETE_TASK_COMMENT_SUCCESS = "DELETE_TASK_COMMENT_SUCCESS";
export const DELETE_TASK_COMMENT_ERROR = "DELETE_TASK_COMMENT_ERROR";

export const RENAME_PROJECT_NAME = "RENAME_PROJECT_NAME";
export const RENAME_PROJECT_NAME_SUCCESS = "RENAME_PROJECT_NAME_SUCCESS";
export const RENAME_PROJECT_NAME_ERROR = "RENAME_PROJECT_NAME_ERROR";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_ERROR = "DELETE_PROJECT_ERROR";

//start annotation page
export const CHANGE_TASK_STATUS = "CHANGE_TASK_STATUS";
export const CHANGE_TASK_STATUS_SUCCESS = "CHANGE_TASK_STATUS_SUCCESS";
export const CHANGE_TASK_STATUS_ERROR = "CHANGE_TASK_STATUS_ERROR";
export const CATEGORY_TASKS_LIST_WITH_VERSIONIMG_START = "CATEGORY_TASKS_LIST_WITH_VERSIONIMG_START";
export const CATEGORY_TASKS_LIST_WITH_VERSIONIMG_SUCCESS = "CATEGORY_TASKS_LIST_WITH_VERSIONIMG_SUCCESS";
export const CATEGORY_TASKS_LIST_WITH_VERSIONIMG_ERROR = "CATEGORY_TASKS_LIST_WITH_VERSIONIMG_ERROR";

export const CHANGE_TASK_MAINIMG_STATUS = "CHANGE_TASK_MAINIMG_STATUS";
export const CHANGE_TASK_MAINIMG_SUCCESS = "CHANGE_TASK_MAINIMG_SUCCESS";
export const CHANGE_TASK_MAINIMG_ERROR = "CHANGE_TASK_MAINIMG_ERROR";
export const TASKS_MAINIMG_LIST_START = "TASKS_MAINIMG_LIST_START";
export const TASKS_MAINIMG_LIST_START_SUCCESS = "TASKS_MAINIMG_LIST_START_SUCCESS";
export const TASKS_MAINIMG_LIST_START_ERROR = "TASKS_MAINIMG_LIST_START_ERROR";
//end annotation

export const CATEGORY_TASKS_COMPARE_IMAGE = "CATEGORY_TASKS_COMPARE_IMAGE";
export const CATEGORY_TASKS_COMPARE_IMAGE_SUCCESS = "CATEGORY_TASKS_COMPARE_IMAGE_SUCCESS";
export const CATEGORY_TASKS_COMPARE_IMAGE_ERROR = "CATEGORY_TASKS_COMPARE_IMAGE_ERROR";

export const CATEGORY_TASKS_COMPARE_IMAGE_PREPROD = "CATEGORY_TASKS_COMPARE_IMAGE_PREPROD";
export const CATEGORY_TASKS_COMPARE_IMAGE_PREPROD_SUCCESS = "CATEGORY_TASKS_COMPARE_IMAGE_PREPROD_SUCCESS";
export const CATEGORY_TASKS_COMPARE_IMAGE_PREPROD_ERROR = "CATEGORY_TASKS_COMPARE_IMAGE_PREPROD_ERROR";

export const TASK_ADD_NEW_ANNOTATE_MAIN_IMG = "TASK_ADD_NEW_ANNOTATE_MAIN_IMG";
export const TASK_ADD_NEW_ANNOTATE_MAIN_IMG_SUCCESS = "TASK_ADD_NEW_ANNOTATE_MAIN_IMG_SUCCESS";
export const TASK_ADD_NEW_ANNOTATE_MAIN_IMG_ERROR = "TASK_ADD_NEW_ANNOTATE_MAIN_IMG_ERROR";

export const NOTIFICATION_LIST_SUCCESS = "NOTIFICATION_LIST_SUCCESS";
export const NOTIFICATION_LIST_FAILED = "NOTIFICATION_LIST_FAILED";
export const NOTIFICATION_LIST_START = "NOTIFICATION_LIST_START";

export const NOTIFICATION_READ_START = "NOTIFICATION_READ_START";
export const NOTIFICATION_READ_SUCCESS = "NOTIFICATION_READ_SUCCESS";
export const NOTIFICATION_READ_FAILED = "NOTIFICATION_READ_FAILED";
export const NOTIFICATION_READ_ALL_FAILED = "NOTIFICATION_READ_ALL_FAILED";
export const GO_TO_NOTIFICATION = "GO_TO_NOTIFICATION";

export const ADDUPD_TASK_ASSIGNMEMBERS_START = "ADDUPD_TASK_ASSIGNMEMBERS_START";
export const ADDUPD_TASK_ASSIGNMEMBERS_SUCCESS = "ADDUPD_TASK_ASSIGNMEMBERS_SUCCESS";
export const ADDUPD_TASK_ASSIGNMEMBERS_ERROR = "ADDUPD_TASK_ASSIGNMEMBERS_ERROR";

export const DEL_PROJECT_MEMBER_START = "DEL_PROJECT_MEMBER_START";
export const DEL_PROJECT_MEMBER_SUCCESS = "DEL_PROJECT_MEMBER_SUCCESS";
export const DEL_PROJECT_MEMBER_ERROR = "DEL_PROJECT_MEMBER_ERROR";

export const DEL_ROLEMGMT_START = "DEL_ROLEMGMT_START";
export const DEL_ROLEMGMT_SUCCESS = "DEL_ROLEMGMT_SUCCESS";
export const DEL_ROLEMGMT_ERROR = "DEL_ROLEMGMT_ERROR";

export const ARCHIVE_PROJECT_LIST_START = "ARCHIVE_PROJECT_LIST_START";
export const ARCHIVE_PROJECT_LIST_SUCCESS = "ARCHIVE_PROJECT_LIST_SUCCESS";
export const ARCHIVE_PROJECT_LIST_ERROR = "ARCHIVE_PROJECT_LIST_ERROR";

export const GET_TASK_IMAGE_WO = "GET_TASK_IMAGE_WO";
export const GET_TASK_IMAGE_SUCCESS_WO = "GET_TASK_IMAGE_SUCCESS_WO";
export const GET_TASK_IMAGE_ERROR_WO = "GET_TASK_IMAGE_ERROR_WO";

export const GET_TASK_VERSION_IMAGE = "GET_TASK_VERSION_IMAGE";
export const GET_TASK_VERSION_IMAGE_SUCCESS = "GET_TASK_VERSION_IMAGE_SUCCESS";
export const GET_TASK_VERSION_IMAGE_ERROR = "GET_TASK_VERSION_IMAGE_ERROR";

export const CHAT_PROJECT_LIST_START = "CHAT_PROJECT_LIST_START";
export const CHAT_PROJECT_LIST_SUCCESS = "CHAT_PROJECT_LIST_SUCCESS";
export const CHAT_PROJECT_LIST_FAILED = "CHAT_PROJECT_LIST_FAILED";

export const GET_RECENT_CHAT_USERS_START = "GET_RECENT_CHAT_USERS_START";
export const GET_RECENT_CHAT_USERS_FAILED = "GET_RECENT_CHAT_USERS_FAILED";
export const GET_RECENT_CHAT_USERS_SUCCESS = "GET_RECENT_CHAT_USERS_SUCCESS";

export const DELETE_GROUP_CHAT_START = "DELETE_GROUP_CHAT_START";
export const DELETE_GROUP_CHAT_SUCCESS = "DELETE_GROUP_CHAT_SUCCESS";
export const DELETE_GROUP_CHAT_FAIL = "DELETE_GROUP_CHAT_FAIL";

export const DELETE_CHAT_USER_START = "DELETE_CHAT_USER_START";
export const DELETE_CHAT_USER_SUCCESS = "DELETE_CHAT_USER_SUCCESS";
export const DELETE_CHAT_USER_FAIL = "DELETE_CHAT_USER_FAIL";

export const GET_USER_CHAT_SUCCESS = "GET_USER_CHAT_SUCCESS";
export const GET_USER_CHAT_FAILED = "GET_USER_CHAT_FAILED";
export const GET_USER_CHAT_START = "GET_USER_CHAT_START";
export const ALL_MESSAGES = "ALL_MESSAGES";
export const REMOVE_ALL_MESSAGES = "REMOVE_ALL_MESSAGES";

export const NOTIFICATION_ONOFF_START = "NOTIFICATION_ONOFF_START";
export const NOTIFICATION_ONOFF_SUCCESS = "NOTIFICATION_ONOFF_SUCCESS";
export const NOTIFICATION_ONOFF_FAILED = "NOTIFICATION_ONOFF_FAILED";

//Start for super admin apis
export const GET_SUBSCRIBER_LIST_START = "GET_SUBSCRIBER_LIST_START";
export const GET_SUBSCRIBER_LIST_SUCCESS = "GET_SUBSCRIBER_LIST_SUCCESS";
export const GET_SUBSCRIBER_LIST_FAILED = "GET_SUBSCRIBER_LIST_FAILED";

export const BLOCK_SUBSCRIBER_START = "BLOCK_SUBSCRIBER_START";
export const BLOCK_SUBSCRIBER_SUCCESS = "BLOCK_SUBSCRIBER_SUCCESS";
export const BLOCK_SUBSCRIBER_FAILED = "BLOCK_SUBSCRIBER_FAILED";
//End for super admin apis 

export const PROJ_LIST_START = "PROJ_LIST_START";
export const PROJ_LIST_SUCCESS = "PROJ_LIST_SUCCESS";
export const PROJ_LIST_FAILED = "PROJ_LIST_FAILED";

export const PROJCAT_LIST_START = "PROJCAT_LIST_START";
export const PROJCAT_LIST_SUCCESS = "PROJCAT_LIST_SUCCESS";
export const PROJCAT_LIST_FAILED = "PROJCAT_LIST_FAILED";

//upload final images tab start
export const SET_UPLOAD_FILE = "SET_UPLOAD_FILE";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const SUCCESS_UPLOAD_FILE = "SUCCESS_UPLOAD_FILE";
export const FAILURE_UPLOAD_FILE = "FAILURE_UPLOAD_FILE";
export const RETRY_UPLOAD_FILE = "RETRY_UPLOAD_FILE";
export const FAIL_UPLOAD_FILE = "FAIL_UPLOAD_FILE";
export const CLOSE_UPLOAD_FILE = "CLOSE_UPLOAD_FILE";
//end upload final images tab

export const ARCHIVE_PROJECT = "ARCHIVE_PROJECT";
export const ARCHIVE_PROJECT_SUCCESS = "ARCHIVE_PROJECT_SUCCESS";
export const ARCHIVE_PROJECT_ERROR = "ARCHIVE_PROJECT_ERROR";

export const PROJECT_FILE_MANAGER_LIST = "PROJECT_FILE_MANAGER_LIST";
export const PROJECT_FILE_MANAGER_LIST_SUCCESS = "PROJECT_FILE_MANAGER_LIST_SUCCESS";
export const PROJECT_FILE_MANAGER_LIST_ERROR = "PROJECT_FILE_MANAGER_LIST_ERROR";

export const PROJECT_UPLOAD_FILE = "PROJECT_UPLOAD_FILE";
export const PROJECT_UPLOAD_FILE_SUCCESS = "PROJECT_UPLOAD_FILE_SUCCESS";
export const PROJECT_UPLOAD_FILE_ERROR = "PROJECT_UPLOAD_FILE_ERROR";

export const RENAME_PROJECT_MEMBER_START = "RENAME_PROJECT_MEMBER_START";
export const RENAME_PROJECT_MEMBER_SUCCESS = "RENAME_PROJECT_MEMBER_SUCCESS";
export const RENAME_PROJECT_MEMBER_ERROR = "RENAME_PROJECT_MEMBER_ERROR";

export const SEARCH_LIST = "SEARCH_LIST";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_SUPER_ADMIN_DASHBOARD_DATA = "GET_SUPER_ADMIN_DASHBOARD_DATA";


export const GET_PLANS_DATA = "GET_PLANS_DATA";
export const PROJECT_CATEGORY_LIST_CLEAR = "PROJECT_CATEGORY_LIST_CLEAR";



  
export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
//export * from "./user/actions";
export * from "./superadmin/actions";
export * from "./uploadFile/actions";

export * from "./category/actions";
export * from "./project/actions";
export * from "./task/actions";
export * from "./notifications/actions";

export * from "./todo/actions";
export * from "./chat/actions";
export * from "./surveyList/actions";
export * from "./surveyDetail/actions";
export * from "./dashboard/actions";

export * from "./plans/actions";


