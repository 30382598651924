import {
    CHAT_GET_CONTACTS,
    CHAT_GET_CONTACTS_SUCCESS,
    CHAT_GET_CONTACTS_ERROR,
    CHAT_GET_CONVERSATIONS,
    CHAT_GET_CONVERSATIONS_SUCCESS,
    CHAT_GET_CONVERSATIONS_ERROR,
    CHAT_ADD_MESSAGE_TO_CONVERSATION,
    CHAT_CREATE_CONVERSATION,
    CHAT_SEARCH_CONTACT,
    CHAT_CHANGE_CONVERSATION,
    CHAT_PROJECT_LIST_START,
    CHAT_PROJECT_LIST_SUCCESS,
    CHAT_PROJECT_LIST_FAILED,
    GET_RECENT_CHAT_USERS_START,
    GET_RECENT_CHAT_USERS_SUCCESS,
    GET_RECENT_CHAT_USERS_FAILED,
    GET_USER_CHAT_START,
    GET_USER_CHAT_FAILED,
    GET_USER_CHAT_SUCCESS,
    ALL_MESSAGES,
    REMOVE_ALL_MESSAGES,
    CHAT_CREATE_GROUP,
    CHAT_CREATE_GROUP_SUCCESS,
    CHAT_CREATE_GROUP_ERROR,
    DELETE_GROUP_CHAT_START,
    DELETE_GROUP_CHAT_SUCCESS,
    DELETE_GROUP_CHAT_FAIL,
    DELETE_CHAT_USER_START,
    DELETE_CHAT_USER_SUCCESS,
    DELETE_CHAT_USER_FAIL,
} from '../actions';

import config from "../../config/config";
import { NotificationManager } from "../../../src/components/common/react-notifications";
import axios from "axios";
import * as actions from "../../redux/actions";
import firebase from 'firebase';
import moment from 'moment';
import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';

export const getContacts = () => ({
    type: CHAT_GET_CONTACTS
});

export const getContactsSuccess = (contacts, currentUser) => {
    return ({
        type: CHAT_GET_CONTACTS_SUCCESS,
        payload: { contacts, currentUser }
    })
};

export const getContactsError = (error) => ({
    type: CHAT_GET_CONTACTS_ERROR,
    payload: error
});

export const getConversations = (userId) => ({
    type: CHAT_GET_CONVERSATIONS,
    payload:  userId 
});
export const getConversationsSuccess = (conversations, selectedUser) => ({
    type: CHAT_GET_CONVERSATIONS_SUCCESS,
    payload: { conversations, selectedUser }
});

export const getConversationsError = (error) => ({
    type: CHAT_GET_CONVERSATIONS_ERROR,
    payload: error
});

export const addMessageToConversation = (currentUserId,selectedUserId,message,allConversations) => ({
    type: CHAT_ADD_MESSAGE_TO_CONVERSATION,
    payload: {currentUserId,selectedUserId,message,allConversations}
});

export const createConversation = (currentUserId,selectedUserId,allConversations) => {
    return ({
        type: CHAT_CREATE_CONVERSATION,
        payload: {currentUserId,selectedUserId,allConversations}
    })
};

export const searchContact = (keyword) => ({
    type: CHAT_SEARCH_CONTACT,
    payload: keyword
});

export const changeConversation = (userId) => ({
    type: CHAT_CHANGE_CONVERSATION,
    payload: userId
});

export const getchatProjectsList = (request) => {
    return (dispatch) => {
        dispatch(ongetProjectsListStart());
        let url = config.baseUrl + "projectlist-chat";
        let token = localStorage.getItem("UserToken");
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(function (response) {
            dispatch(ongetProjectsListSucceed(response.data));
        })
        .catch(function (error) {
            dispatch(ongetProjectsListFailed(error));
        });
    };
  };
  
  const ongetProjectsListStart = () => {
    return {
      type: CHAT_PROJECT_LIST_START,
    };
  };
  
  const ongetProjectsListFailed = (error) => {
    return {
      type: CHAT_PROJECT_LIST_FAILED,
      error: error,
    };
  };
  
  const ongetProjectsListSucceed = (data) => {
    return {
      type: CHAT_PROJECT_LIST_SUCCESS,
      state: data,
    };
  };

/**
 * Get Recent Chat User
 */
export const getRecentChatUsers = (request) => {
    // type: GET_RECENT_CHAT_USERS
    return (dispatch) => {
      dispatch(onrecentChatUsersListStart());
        
      let url = config.baseUrl + "recent-chat-user";
      let token = localStorage.getItem("UserToken");
       axios
        .post(url, request, {
            headers: {
                Authorization: `Bearer ${token}`,
                //"Content-Type": "multipart/form-data",
            },
        })
        .then(function (response) {
          dispatch(onrecentChatUsersListSucceed(response));
        })
        .catch(function (error) {
          dispatch(onrecentChatUsersListFailed(error));
        });
    };
};
const onrecentChatUsersListStart = () => {
  return {
    type: GET_RECENT_CHAT_USERS_START,
  };
};

const onrecentChatUsersListFailed = (error) => {
  return {
    type: GET_RECENT_CHAT_USERS_FAILED,
    error: error,
  };
};

const onrecentChatUsersListSucceed = (data) => {
  return {
    type: GET_RECENT_CHAT_USERS_SUCCESS,
    state: data,
  };
};
 
/* get user chat */
export const getUserChat = (request) => {
    return (dispatch) => {
      dispatch(ongetUseChatStart());
      let url = config.baseUrl + "user-chat";
      let token = localStorage.getItem("UserToken");
       axios
        .post(url, request, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
        .then(function (response) {
          dispatch(ongetUseChatSucceed(response));
        })
        .catch(function (error) {
          dispatch(ongetUseChatFailed(error));
        });
    };
  };
  const ongetUseChatStart = () => {
    return {
      type: GET_USER_CHAT_START,
    };
  };
  
  const ongetUseChatFailed = (error) => {
    return {
      type: GET_USER_CHAT_FAILED,
      error: error,
    };
  };
  
  const ongetUseChatSucceed = (data) => {
    return {
      type: GET_USER_CHAT_SUCCESS,
      state: data,
    };
  };

//create group
export const createGroup = (userdata, state, selected_project) => {
  return dispatch => {
    var groupId = uuidv4(); 
    let currentTimestamp = moment().unix();
   
    let groupdata = {
      admin: userdata.firebase_id,
      adminid: userdata.id,
      created_time: currentTimestamp,
      displayname: '',
      group_image: '',
      groupid: groupId,
    }  

    firebase.database().ref('projects/' + selected_project.id +'/groups/').once('value', function(snapshot) {
        firebase.database().ref('projects/' + selected_project.id + "/groups/" + groupId + "/details").set(groupdata).then(() => {
          firebase.database().ref('projects/' + selected_project.id + "/groups/" + groupId + '/unreadcount/').once("value", (snap) => {
            //new code
            var cnt = 0;
            var getcountJson = {};
            state.groupAssignMembers.map((value,index) => {
              getcountJson[value] = cnt;
            })
            getcountJson[userdata.firebase_id] = cnt;
            //console.log('getcountJson',getcountJson)
            firebase.database().ref('projects/' + selected_project.id + "/groups/" + groupId + '/unreadcount/').set(getcountJson);
            //end new   
          });        
        })  
    }); 

    //create group in Database
    let groupAssignMembers = state.groupAssignMembers;
    groupAssignMembers.push(userdata.firebase_id);

    let reqPar = {
      project_id: selected_project.id,
      group_id: groupId,
      admin_firebase_id: userdata.firebase_id,
      admin_id: userdata.id,
      display_name: "",
      group_image: "",
      assign_members: groupAssignMembers.join(),
    };
    dispatch(actions.createGroupDatabase(reqPar));
  }
}

//create new group on database
export const createGroupDatabase = (payload) => {
  return (dispatch) => {
    dispatch({ type: CHAT_CREATE_GROUP });
    let apiEndpoint = "create-group-chat";
    const token = localStorage.getItem("UserToken");
    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //console.log("SUCCESS", response);
        if (response) {
          dispatch({ type: CHAT_CREATE_GROUP_SUCCESS, data: response });

          //refresh recent chat users
          let reqPar = {
            project_id: response.data.data.project_id,
          };
          dispatch(actions.getRecentChatUsers(reqPar))
          
          NotificationManager.success(
            response.data.message,
            "Success",
            3000,
            null,
            null,
            ""
          );
        }
      })
      .catch(function (error) {
        //console.log("error", error);
        dispatch({
          type: CHAT_CREATE_GROUP_ERROR,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

//delete group
export const deleteGroupChat = (payload) => {
  return (dispatch) => {
    dispatch({ type: DELETE_GROUP_CHAT_START });
    let apiEndpoint = "delete-group-chat";
    const token = localStorage.getItem("UserToken");
    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response) {
          dispatch({ type: DELETE_GROUP_CHAT_SUCCESS, data: response });

          //refresh recent chat users
          let reqPar = {
            project_id: response.data.data.project_id,
          };
          dispatch(actions.getRecentChatUsers(reqPar))
          
          NotificationManager.success(
            response.data.message,
            "Success",
            3000,
            null,
            null,
            ""
          );
        }
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_GROUP_CHAT_FAIL,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

//delete chat users project wise
export const deleteChatUser = (payload) => {
  return (dispatch) => {
    dispatch({ type: DELETE_CHAT_USER_START });
    let apiEndpoint = "delete-users-chat";
    const token = localStorage.getItem("UserToken");
    axios
      .post(config.baseUrl + apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response) {
          dispatch({ type: DELETE_CHAT_USER_SUCCESS, data: response });

          //refresh recent chat users
          let reqPar = {
            project_id: response.data.data.project_id,
          };
          dispatch(actions.getRecentChatUsers(reqPar))
          
          NotificationManager.success(
            response.data.message,
            "Success",
            3000,
            null,
            null,
            ""
          );
        }
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_CHAT_USER_FAIL,
          error: error.response.data.message,
        });
        NotificationManager.warning(
          error.response.data.message,
          "Warning",
          3000,
          null,
          null,
          ""
        );
      });
  };
};

//send msg
export const sendMessage = (userdata, state) => {
  //console.log('sendMessage',state);
  if(state.selected_User.is_group == 1){
    let receiveroriginalString = state.selected_User.receiver_firebase_ids;
    let receiverUidArray = receiveroriginalString.split(','); 
    //console.log('receiveArray',receiverUidArray);

    //return false;
    //Group msg
    let user_image = "";
    if (userdata.profile_photo) {
      user_image = userdata.profile_photo;
    } else {
      user_image = "";
    }

    var SenderUid = firebase.auth().currentUser.uid;
    var groupId = state.selected_User.group_id;
    
    return dispatch => {
      if (state.messageInput.trim() !== "" || state.url !== "") {
        let currentTimestamp = moment().unix();
        let msgdata = {
          message: state.messageInput,
          senderName: userdata.name,
          senderUid: userdata.firebase_id,
          senderImage: user_image,
          timestamp: currentTimestamp,
        }  
       
        firebase.database().ref('projects/' + state.selected_project.id +'/groups/').once('value', function(snapshot) {
            var getcountJson = {};
            
            firebase.database().ref('projects/' + state.selected_project.id + "/groups/" + groupId + "/messages/" + currentTimestamp).set(msgdata).then(() => {
              firebase.database().ref('projects/' + state.selected_project.id + "/groups/" + groupId + '/unreadcount/').once("value", (snap) => {
                //new code
                getcountJson = snap.val();
                var currentReceiverCount = 0;
                var currentSenderCount = 0;
                var nextCountReceiver = 1;
                if (getcountJson != null && !$.isEmptyObject(getcountJson)) {
                  $.each(receiverUidArray, function(key, val) {
                    currentReceiverCount = getcountJson[val];
                    nextCountReceiver = parseInt(currentReceiverCount) + 1;
                    getcountJson[val] = nextCountReceiver;
                  })
                  getcountJson[SenderUid] = getcountJson[SenderUid];
                } else {
                    getcountJson = {};
                }
                //getcountJson[SenderUid] = currentSenderCount;
                //console.log('getcountJson',getcountJson)
                //console.log('pid',state.selected_project.id)
                firebase.database().ref('projects/' + state.selected_project.id + "/groups/" + groupId + '/unreadcount/').set(getcountJson);
                //end new   
              });        
            })  
        });
      }
    }

  }else{
    //chat room msg for one to one user
    let user_image = "";
    if (userdata.profile_photo) {
      user_image = userdata.profile_photo;
    } else {
      user_image = "";
    }


    var SenderUid = firebase.auth().currentUser.uid;
    //var SenderUid = userdata.firebase_id;
    var receiverUid = state.selected_User.firebase_id;
    //console.log('receiverUid',receiverUid)
    var chat_room_key_1 = userdata.firebase_id+'_'+state.selected_User.firebase_id;
    var chat_room_key_2 = state.selected_User.firebase_id + '_' + userdata.firebase_id;
    //console.log(chat_room_key_1)
    //console.log(chat_room_key_2)

    return dispatch => {
      if (state.messageInput.trim() !== "" || state.url !== "") {
        let currentTimestamp = moment().unix();
        let msgdata = {
          message: state.messageInput,
          receiverName: state.selected_User.name,
          receiverUid: state.selected_User.firebase_id,
          senderName: userdata.name,
          senderUid: userdata.firebase_id,
          senderImage: user_image,
          //filename: state.filename ? state.filename : "",
          //filenameStorage: state.filenamestorage ? state.filenamestorage : "",
          //url: state.url != "" ? state.url : null,
          timestamp: currentTimestamp,
        }  

        

        firebase.database().ref('projects/' + state.selected_project.id +'/chat_rooms/').once('value', function(snapshot) {
            let final_chat_room_key;
            var getcountJson = {};
            //var getcountJson = [];
                          
            if (snapshot.hasChild(chat_room_key_1)) {
              final_chat_room_key = chat_room_key_1;
              //firebasedata[userdata.firebase_id] = 0;
            } else if (snapshot.hasChild(chat_room_key_2)) {
              final_chat_room_key = chat_room_key_2;
              //firebasedata[state.selected_User.firebase_id] = 1;
            } else {
              final_chat_room_key = chat_room_key_1;
              //firebasedata[userdata.firebase_id] = 0;
            }

            firebase.database().ref('projects/' + state.selected_project.id + "/chat_rooms/" + final_chat_room_key + "/messages/" + currentTimestamp).set(msgdata).then(() => {
              firebase.database().ref('projects/' + state.selected_project.id + "/chat_rooms/" + final_chat_room_key + '/unreadcount/').once("value", (snap) => {
                //new code
                getcountJson = snap.val();
                var currentReceiverCount = 0;
                var currentSenderCount = 0;
                var nextCountReceiver = 1;
                if (getcountJson != null && !$.isEmptyObject(getcountJson)) {
                    currentReceiverCount = getcountJson[receiverUid];
                    currentSenderCount = getcountJson[SenderUid];
                    nextCountReceiver = parseInt(currentReceiverCount) + 1;

                } else {
                    getcountJson = {};
                }
                getcountJson[SenderUid] = currentSenderCount;
                getcountJson[receiverUid] = nextCountReceiver;
                // console.log('getcountJson',getcountJson)
                // console.log('final_chat_room_key',final_chat_room_key)
                // console.log('pid',state.selected_project.id)
                firebase.database().ref('projects/' + state.selected_project.id + "/chat_rooms/" + final_chat_room_key + '/unreadcount/').set(getcountJson);
                //end new   
              });        
            })  
        });
      }
    }
  }
}

/**
 * All messages of project
 */
export const getProjectMessages = (projectid, userdata, state, selectedUserUid) => {
  var chat_room_key_1 = userdata.firebase_id+'_'+selectedUserUid;
  var chat_room_key_2 = selectedUserUid + '_' + userdata.firebase_id;
  //console.log('getProjectMessages')
  //console.log('state',state.selected_User)
  return dispatch => {
    if(state.selected_User.is_group == 1){
      //group chat
        var groupId = state.selected_User.group_id;
        firebase.database().ref('projects/' + projectid +'/groups/').orderByValue().on('value', function(snapshot) {
          var getcountJson = {};
          if (snapshot.hasChild(groupId)) {
              firebase.database().ref('projects/' + projectid +'/groups/' + groupId).on('value', function(snap) {
                let allMessages = [];
                snap.forEach((value) => {
                  if (value.key === "messages") {
                    value.forEach((msg) => {
                      allMessages.push(msg.val())
                    })
                  }
                  if (value.key === "unreadcount") {
                    value.forEach((unreadcount) => {
                      if (unreadcount.key === userdata.firebase_id) {
                        firebase.database().ref('projects/' + projectid +'/groups/' + groupId + '/unreadcount/' + userdata.firebase_id).set(0);
                      }
                    })
                  }
                })
                //console.log("allMessages1",allMessages)
                /**
                 * Save all messages in reducer
                 */
                dispatch(saveAllMessage(allMessages))
              });
          }  else {
            let allMessages = [];
            /**
             * Save all messages in reducer
             */
            dispatch(saveAllMessage(allMessages))
          }
      });
    }else{
      //chat room for single user
      firebase.database().ref('projects/' + projectid +'/chat_rooms/').orderByValue().on('value', function(snapshot) {
          var getcountJson = {};
          if (snapshot.hasChild(chat_room_key_1)) {
              firebase.database().ref('projects/' + projectid +'/chat_rooms/' + chat_room_key_1).on('value', function(snap) {
                let allMessages = [];
                snap.forEach((value) => {
                  if (value.key === "messages") {
                    value.forEach((msg) => {
                      allMessages.push(msg.val())
                    })
                  }
                  if (value.key === "unreadcount") {
                    value.forEach((unreadcount) => {
                      if (unreadcount.key === userdata.firebase_id) {
                        firebase.database().ref('projects/' + projectid +'/chat_rooms/' + chat_room_key_1 + '/unreadcount/' + userdata.firebase_id).set(0);
                      }
                    })
                  }
                })
                //console.log("allMessages1",allMessages)
                /**
                 * Save all messages in reducer
                 */
                dispatch(saveAllMessage(allMessages))
              });
          } else if (snapshot.hasChild(chat_room_key_2)) {
              firebase.database().ref('projects/' + projectid +'/chat_rooms/' + chat_room_key_2 ).on('value', function(snap) {
                let allMessages = [];
                snap.forEach((value) => {
                  if (value.key === "messages") {
                    value.forEach((msg) => {
                      allMessages.push(msg.val())
                    })
                  }
                  if (value.key === "unreadcount") {
                    value.forEach((unreadcount) => {
                      if (unreadcount.key === userdata.firebase_id) {
                        firebase.database().ref('projects/' + projectid +'/chat_rooms/' + chat_room_key_2 + '/unreadcount/' + userdata.firebase_id).set(0);
                      }
                    })
                  }
                })
                //console.log("allMessages2",allMessages)
                /**
                 * Save all messages in reducer
                 */
                dispatch(saveAllMessage(allMessages))
              });
          } else {
            let allMessages = [];
            /**
             * Save all messages in reducer
             */
            dispatch(saveAllMessage(allMessages))
          }
      });
    }
  }
}

const saveAllMessage = (data) => {
  return ({
    type: ALL_MESSAGES,
    state: data
  })
}

export const removeAllMsgs = () => {
  return {
    type: REMOVE_ALL_MESSAGES,
    state: []
  }
}