import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHECK_TOKEN,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_ERROR,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPLOAD_AVTAR,
  UPLOAD_AVTAR_SUCCESS,
  UPLOAD_AVTAR_ERROR,
  GET_SINGLE_MEMBER_DETAIL,
  GET_SINGLE_MEMBER_DETAIL_SUCCESS,
  GET_SINGLE_MEMBER_DETAIL_ERROR,
  REGISTER_TEAM_MEMBER,
  REGISTER_TEAM_MEMBER_SUCCESS,
  REGISTER_TEAM_MEMBER_ERROR,
  UPLOAD_PROFILE_PHOTO,
  UPLOAD_PROFILE_PHOTO_SUCCESS,
  UPLOAD_PROFILE_PHOTO_ERROR,
  NOTIFICATION_ONOFF_START,
  NOTIFICATION_ONOFF_SUCCESS,
  NOTIFICATION_ONOFF_FAILED,
} from "../actions";

// import {
//   updateProjectObject,
// } from "../../util/utility";

const INIT_STATE = {
  user: localStorage.getItem("UserToken"),
  update_user_profile: "",
  forgotUserMail: "",
  newPassword: "",
  resetPasswordCode: "",
  loading: false,
  error: "",
  UserInfo: "",
  notiSetting: "",
  //categories: [],
  // projectsListData: [],
  // ProjectInfo: [],
  // customRole: [],
  // defaultPageSize: "",
  fields: {
    email: {
      value: "",
      error: false,
      message: "",
    },
    password: {
      value: "",
      error: false,
      message: "",
    },
    name: {
      value: "",
      error: false,
      message: "",
    },
    contact_number: {
      value: "",
      error: false,
      message: "",
    },
    address: {
      value: "",
    },
    photo: {
      value: "",
    },
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: "" };
    case LOGIN_USER_SUCCESS:
      //console.log("login user", action);
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: "",
        error: action.error,
      };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: "" };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        error: action.message,
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: "",
        error: "",
      };
    case RESET_PASSWORD:
      return { ...state, loading: true, error: "" };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: "",
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case REGISTER_USER:
      return {
        ...state,
        message: "",
        loading: true,
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case REGISTER_USER_ERROR:
      //console.log("regierror", action);
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case LOGOUT_USER:
      return { ...state, loading: false, user: null, error: "" };
    case CHECK_TOKEN:
      return { ...state, loading: false, error: "" };
    case CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        UserInfo: action.message.data,
        type: action.type,
        error: "",
      };
    case CHECK_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        UserInfo: "",
        error: action,
      };

    case GET_USER:
      return { ...state, loading: false, error: "" };
    case GET_USER_SUCCESS:
      let userDetails = action.message.data.data.user_info;
      return {
        ...state,
        loading: false,
        type: action.type,
        UserInfo: action.message.data,
        fields: {
          email: {
            value: userDetails.email,
            error: false,
            message: "",
          },
          confirmPassword: {
            value: "",
            error: false,
            message: "",
          },
          newPassword: {
            value: "",
            error: false,
            message: "",
          },
          oldPassword: {
            value: "",
            error: false,
            message: "",
          },
          name: {
            value: userDetails.name,
            error: false,
            message: "",
          },
          role: {
            value: userDetails.role,
            //error: false,
            //message: "",
          },
          contact_number: {
            value: userDetails.contact_number,
            error: false,
            message: "",
          },
          company_name: {
            value: userDetails.company_name,
            error: false,
            message: "",
          },
          address: {
            value: userDetails.address,
          },
          profile_photo: {
            value: userDetails.profile_photo,
          },
          type: {
            value: userDetails.type,
          }
        },
      };
    case GET_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case UPDATE_USER:
      return {
        ...state,
        message: "",
        loading: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        update_user_profile: action.userData,
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        update_user_profile: "not valid",
      };
    case UPLOAD_AVTAR:
      return { ...state, loading: true, error: "" };
    case UPLOAD_AVTAR_SUCCESS:
      return {
        ...state,
        loading: false,
        user: "",
      };
    case UPLOAD_AVTAR_ERROR:
      return {
        ...state,
        loading: false,
        user: "",
        error: "",
      };
    case GET_SINGLE_MEMBER_DETAIL:
      return { ...state, loading: false, error: "" };
    case GET_SINGLE_MEMBER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        UserInfo: action.message.data.data,
        type: action.type,
        error: "",
      };
    case GET_SINGLE_MEMBER_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        UserInfo: "",
        error: action,
      };
    case REGISTER_TEAM_MEMBER:
      return {
        ...state,
        message: "",
        loading: true,
      };
    case REGISTER_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case REGISTER_TEAM_MEMBER_ERROR:
      //console.log("regerror", action);
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case UPLOAD_PROFILE_PHOTO:
      return {
        ...state,
        message: "",
        loading: true,
      };
    case UPLOAD_PROFILE_PHOTO_SUCCESS:
      return {
        ...state,
        loading: false,
        update_user_profile: action.userData,
      };
    case UPLOAD_PROFILE_PHOTO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case NOTIFICATION_ONOFF_START:
      return {
        ...state,
        message: "",
      };
    case NOTIFICATION_ONOFF_SUCCESS:
      return {
        ...state,
        notiSetting: action.userNotiData,
      };
    case NOTIFICATION_ONOFF_FAILED:
      return {
        ...state,
        error: action.error,
      };

    default:
      return { ...state };
  }
};
