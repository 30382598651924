import {
  GET_DASHBOARD_DATA,
  GET_SUPER_ADMIN_DASHBOARD_DATA
} from "../actions";

import { updateProjectObject } from "../../util/utility";

const INIT_STATE = {
  data:{},
  superadminData: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA:
      return { ...state, loading: false, data: action.data };

      case GET_SUPER_ADMIN_DASHBOARD_DATA:
        return { ...state, loading: false, superadminData: action.data };
    default:
      return { ...state };
  }
};
